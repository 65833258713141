import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";

import IconPlaceholder from "components/icon_placeholder";

function Loading({ className, centerPlacement, text }) {
  const { t } = useTranslation();

  return (
    <IconPlaceholder
      centerPlacement={centerPlacement}
      className={className}
      dataTestid="loading"
      Icon={LoadingOutlined}
      text={text || t("general:loading")}
    />
  );
}

export default Loading;
