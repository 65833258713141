import React from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

import useBoolState from "utils/use_bool_state";

import styles from "./refresh_button.module.css";

const CHECK_DELAY = 500;

export default function RefreshButton({ disabled, children, onClick }) {
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const [isChecked, setIsChecked, setIsUnchecked] = useBoolState(false);
  const { t } = useTranslation();

  const handleRefreshComplete = () => {
    setLoadingComplete();
    setIsChecked();

    setTimeout(setIsUnchecked, CHECK_DELAY);
  };

  const handleRefreshClick = () => {
    setLoading();

    onClick().finally(handleRefreshComplete);
  };

  const iconComponent = isChecked && <CheckCircleOutlined className={styles.checkIcon} />;

  return (
    <Button
      data-testid="refresh_button"
      loading={loading}
      disabled={disabled || loading}
      type="link"
      icon={iconComponent}
      className={styles.refreshButton}
      onClick={handleRefreshClick}
    >
      {children || t("general:action:refresh")}
    </Button>
  );
}
