import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { bookingsApiClient } from "store/api_clients/bookings";
import { getActivePropertyId } from "store/storage/selectors/session_selector";

import channelCodes from "config/constants/channels/channel_codes";

import { useProperty } from "data/use_property";
import { useRatePlans } from "data/use_rate_plans/_v2";
import { useRoomTypes } from "data/use_room_types/_v2";

import { AppForm } from "components/hook_form";
import Loading from "components/loading";
import { showSuccessMessage } from "components/toasts/messages";

import { formatDate, formatDateTime } from "utils/dates";

import { NewBookingWizardFormBody } from "./new_booking_wizard_form_body/new_booking_wizard_form_body";
import { EditBookingFormBody } from "./edit_booking_form_body";
import { validationSchema } from "./validation_schema";

const useUpdateMutation = (id) => {
  return useMutation((params) => bookingsApiClient.update({ id, params }));
};

const useCreateMutation = () => {
  return useMutation((values) => bookingsApiClient.create(values));
};

const useBooking = (bookingId, options = {}) => {
  return useQuery({
    queryKey: ["booking", bookingId],
    queryFn: () => bookingsApiClient.get(bookingId),
    ...options,
  });
};

export const BookingManageForm = ({ bookingId, errors, onFinish }) => {
  const { t } = useTranslation();

  const updateMutation = useUpdateMutation(bookingId);
  const createMutation = useCreateMutation();

  const bookingQuery = useBooking(bookingId, { enabled: !!bookingId });

  const activePropertyId = useSelector(getActivePropertyId);
  const propertyId = bookingId ? bookingQuery.data?.propertyId : activePropertyId;

  const { data: roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true, enabled: !!propertyId });
  const { data: ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { groupResults: true, enabled: !!propertyId });

  const { property, isLoading: propertyIsLoading } = useProperty(propertyId);

  const isBookingLoading = bookingId && (bookingQuery.isLoading || isRoomTypesLoading || isRatePlansLoading);
  const isPropertyLoading = propertyId && propertyIsLoading;

  if (isBookingLoading || isPropertyLoading) {
    return <Loading />;
  }

  const handleSubmit = (values) => {
    if (bookingId) {
      return updateMutation.mutateAsync(values, {
        onSuccess: () => {
          onFinish();
          showSuccessMessage(t("bookings:messages:booking_updated"), { duration: 3 });
        },
      });
    }

    return createMutation.mutateAsync(values, {
      onSuccess: () => {
        onFinish();
        showSuccessMessage(t("bookings:messages:booking_created"), { duration: 3 });
      },
    });
  };

  const buildInitialBooking = (activeProperty) => ({
    propertyId: activeProperty.id,
    currency: activeProperty.currency,
  });

  const uniqueCodes = [...new Set(Object.values(channelCodes).map((item) => item.code))];
  const otaNames = uniqueCodes.map((code) => channelCodes[code]).filter((channel) => !!channel);

  return (
    <AppForm
      initialValue={bookingId ? bookingQuery.data : buildInitialBooking(property)}
      validationSchema={validationSchema}
      errors={errors}
      fieldNames={["rooms", "customer", "services", "deposits"]}
      toForm={({ arrivalDate, departureDate, ...values }) => {
        values.bookingDateRange = [arrivalDate, departureDate];

        if (values.id) {
          // set correct status for fist-time booking edit
          if (values.status === "new") {
            values.status = "modified";
          }
        }

        return values;
      }}
      fromForm={({ bookingDateRange, ...values }) => {
        return ({
          ...values,
          arrivalDate: bookingDateRange ? formatDate(bookingDateRange[0]) : null,
          departureDate: bookingDateRange ? formatDate(bookingDateRange[1]) : null,
          deposits: values.deposits?.map((deposit) => ({
            ...deposit,
            chargedAt: deposit.chargedAt ? formatDateTime(deposit.chargedAt) : null,
          })),
        });
      }}
      onSubmit={handleSubmit}
    >
      {!bookingId && (
        <NewBookingWizardFormBody
          isSubmitting={updateMutation.isLoading || createMutation.isLoading}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
          otaNames={otaNames}
        />
      )}
      {bookingId && (
        <EditBookingFormBody
          bookingId={bookingId}
          isSubmitting={updateMutation.isLoading || createMutation.isLoading}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
          otaNames={otaNames}
        />
      )}
    </AppForm>
  );
};
