import React from "react";
import { useFormContext } from "react-hook-form";
import { Button, Form } from "antd";

import { tailFormItemLayout } from "config/constants/layouts/form";

const FormItem = Form.Item;

export function SubmitButton({ icon, children, loading, disabled, onClick, layout = true }) {
  const formContext = useFormContext();

  const button = (
    <Button
      block
      icon={icon}
      type="primary"
      loading={loading}
      size="large"
      htmlType="submit"
      disabled={disabled}
      onClick={formContext ? formContext.handleSubmit : onClick}
      data-testid="submit"
    >
      {children}
    </Button>
  );

  if (!layout) {
    return button;
  }

  return (
    <FormItem {...tailFormItemLayout}>
      {button}
    </FormItem>
  );
}
