import React from "react";

import { useWizard, WizardProvider } from "./context";

export const Wizard = ({ initialStep, steps, controller, onComplete }) => {
  return (
    <WizardProvider steps={steps} onComplete={onComplete} initialStep={initialStep}>
      <WizardInner
        controller={controller}
      />
    </WizardProvider>
  );
};

const WizardInner = ({ controller }) => {
  const { steps, currentStepIndex } = useWizard();

  return (
    <>
      {steps[currentStepIndex].component}
      {controller}
    </>
  );
};
