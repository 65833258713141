import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Flex } from "antd";

import { ScrollBox } from "components/_v2/layout/scroll_box";
import { useWizard, WizardSidebar } from "components/_v2/wizard";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { AppForm } from "components/hook_form";

import { Services } from "../../common/services";

import { validationSchema } from "./validation_schema";

export const StepFormInner = ({ currency }) => {
  const { t } = useTranslation();
  const { goPrev } = useWizard();

  return (
    <ScrollBox
      content={
        <Services layout="embedded" name="services" currency={currency} />
      }
      sidebar={
        <WizardSidebar />
      }
      footer={
        <Flex style={{ width: "100%" }} gap={8}>
          <Button block size="large" onClick={goPrev}>
            {t("common:actions:prev")}
          </Button>
          <SubmitButton layout={false}>
            {t("common:actions:next")}
          </SubmitButton>
        </Flex>
      }
    />
  );
};

export const Step4 = () => {
  const { goNext } = useWizard();
  const { setValue, getValues, formState } = useFormContext();
  const { currency } = getValues();

  return (
    <AppForm
      initialValue={getValues()}
      errors={formState.errors}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        goNext();
      }}
    >
      <StepFormInner currency={currency} />
    </AppForm>
  );
};
