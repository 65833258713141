import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row } from "antd";
import { getWhiteLabelFlag } from "store/storage/selectors/session_selector";

import WHITE_LABEL_FLAGS from "config/constants/white_label_flags";

import AuthFormTitle from "components/auth_form_title";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";
import withRouter from "routing/with_router";

import PasswordRecoveryForm from "./components/password_recovery_form/password_recovery_form";

function PasswordRecoveryPage() {
  const { t } = useTranslation();
  const isDisableSignUp = useSelector(getWhiteLabelFlag(WHITE_LABEL_FLAGS.DISABLE_SIGN_UP));
  const { publicAppRoutes } = useRouting();

  const rowJustifyPosition = isDisableSignUp ? "center" : "space-between";

  return (
    <>
      <AuthFormTitle>{t("password_recovery_page:header")}</AuthFormTitle>
      <PasswordRecoveryForm />
      <Row justify={rowJustifyPosition}>
        <Link data-testid="sign_in_link" to={pathBuilder(publicAppRoutes.signIn)}>
          {t("public_pages:sign_in_link")}
        </Link>
        {!isDisableSignUp && (
          <Link data-testid="sign_up_link" to={pathBuilder(publicAppRoutes.signUp)}>
            {t("public_pages:sign_up_link")}
          </Link>
        )}
      </Row>
    </>
  );
}

export default withRouter(PasswordRecoveryPage);
