import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import store from "store";
import { channelsApiClient } from "store/api_clients/channels";

import { AppForm, useAppForm } from "components/hook_form";

import { hopperHomesErrorParser } from "../../../../../api/error_parser/hopper_homes_error_parser";

import { ListingFormInner } from "./listing_form_inner";
import { validationSchema } from "./validation_schema";

const { Geocoding } = store;

const mapChannexPropertyTypeToHopperHomes = (propertyType) => {
  const defaultType = "hotel";

  const mapping = {
    apart_hotel: "aparthotel",
    apartment: "apartment",
    boat: "boat",
    camping: "campsite",
    capsule_hotel: "hotel",
    chalet: "chalet",
    country_house: "cottage",
    farm_stay: "farm_stay",
    guest_house: "guesthouse",
    holiday_home: "vacation_home",
    holiday_park: "holiday_park",
    homestay: "house",
    hostel: "hostel",
    hotel: "hotel",
    inn: "hotel",
    lodge: "hotel",
    motel: "hotel",
    resort: "resort",
    riad: "riad",
    ryokan: "hotel",
    tent: "tent",
    villa: "villa",
  };

  return mapping[propertyType] || defaultType;
};

const buildFormInitialValue = ({ listing, property, roomType }) => {
  if (listing) {
    return listing;
  }

  const prefilledNewListing = {};

  prefilledNewListing.countryCode ||= "US";
  prefilledNewListing.currency ||= "USD";
  prefilledNewListing.petsAllowed ||= false;
  prefilledNewListing.smokingAllowed ||= false;
  prefilledNewListing.partiesAllowed ||= false;

  const photos = [];

  if (property) {
    prefilledNewListing.title = property.title;
    prefilledNewListing.description = property.content?.description;
    prefilledNewListing.countryCode = property.country;
    prefilledNewListing.state = property.state;
    prefilledNewListing.city = property.city;
    prefilledNewListing.address = property.address;
    prefilledNewListing.postalCode = property.zipCode;
    prefilledNewListing.latitude = property.latitude;
    prefilledNewListing.longitude = property.longitude;

    prefilledNewListing.propertyType = mapChannexPropertyTypeToHopperHomes(property.propertyType);

    property.content.photos.forEach((photo) => {
      photos.push({ url: photo.url, caption: photo.description });
    });

    const hotelPolicy = Object.values(property.hotelPolicies)[0];

    if (hotelPolicy) {
      prefilledNewListing.petsAllowed = hotelPolicy.petsPolicy === "allowed";
      prefilledNewListing.smokingAllowed = hotelPolicy.smokingPolicy === "allowed";
      prefilledNewListing.checkInStartTime = hotelPolicy.checkinFromTime;
      prefilledNewListing.checkInEndTime = hotelPolicy.checkinToTime;
      prefilledNewListing.checkOutStartTime = hotelPolicy.checkoutFromTime;
      prefilledNewListing.checkOutEndTime = hotelPolicy.checkoutToTime;
    }
  }

  if (roomType) {
    prefilledNewListing.maxAdults = roomType.occ_adults;
    prefilledNewListing.maxChildren = roomType.occ_children;

    roomType.content.photos.forEach((photo) => {
      photos.push({ url: photo.url, caption: photo.description });
    });
  }

  if (photos.length !== 0) {
    prefilledNewListing.photos = photos;
  }

  return prefilledNewListing;
};

const useGeoCodingMutation = () => {
  return useMutation({
    mutationFn: (fullAddress) => Geocoding.getByAddress(fullAddress),
  });
};

const useAmenities = (channelId) => {
  return useQuery({
    queryFn: () => channelsApiClient.amenities(channelId),
  });
};

export const ListingForm = ({ channelId, listing, property, roomType, onBack, onFinish }) => {
  const { t } = useTranslation();

  const geoCodingMutation = useGeoCodingMutation();
  const amenitiesQuery = useAmenities(channelId);

  const createListingMutation = useMutation((values) => channelsApiClient.createListing(channelId, values));
  const updateListingMutation = useMutation((values) => channelsApiClient.updateListing(channelId, values));
  const queryClient = useQueryClient();

  const isSubmitting = createListingMutation.isLoading || updateListingMutation.isLoading;

  const handleFormSubmit = async (values) => {
    const action = values.listingId ? updateListingMutation.mutateAsync : createListingMutation.mutateAsync;

    return action(values, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["listings", channelId] });
        onFinish(values);
      },
    });
  };

  const form = useAppForm({
    initialValue: buildFormInitialValue({ listing, property, roomType }),
    fieldNames: ["title", "description", "registrationNumber", "sizeSquareFeet", "roomType", "propertyType", "currency", "countryCode", "postalCode", "state", "city", "address", "latitude", "longitude", "maxAdults", "maxChildren", "numberOfBeds", "numberOfBedrooms", "numberOfBathrooms", "amenities", "petsAllowed", "smokingAllowed", "partiesAllowed", "houseRules", "checkInInstructions", "checkInStartTime", "checkInEndTime", "checkOutStartTime", "checkOutEndTime", "photos", "rooms"],
    validationSchema,
    apiErrorParser: (error) => hopperHomesErrorParser.parse(error.body.errors, t),
    mode: "onChange",
    reValidateMode: "onChange",
    onSubmit: handleFormSubmit,
  });

  const handleGeoCoding = async () => {
    const values = form.getValues();
    const fullAddress = [values.countryCode, values.state, values.city, values.postalCode, values.address].join(",");

    const { attributes } = await geoCodingMutation.mutateAsync(fullAddress);

    form.setValue("latitude", attributes.location.lat, { shouldValidate: true });
    form.setValue("longitude", attributes.location.lon, { shouldValidate: true });
  };

  const handleCoordsChange = useCallback(({ lat, lng }) => {
    form.setValue("latitude", lat, { shouldValidate: true });
    form.setValue("longitude", lng, { shouldValidate: true });
  }, [form]);

  const isCreate = !listing;

  return (
    <AppForm form={form}>
      <ListingFormInner
        isCreate={isCreate}
        amenitiesQuery={amenitiesQuery}
        isGeoCodingLoading={geoCodingMutation.isLoading}
        onGeoCoding={handleGeoCoding}
        onCoordsChange={handleCoordsChange}
        onBack={onBack}
        isSubmitting={isSubmitting}
      />
    </AppForm>
  );
};
