import React, { useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Col, Input, Row } from "antd";
import classNames from "classnames";

import { filterLookupTable } from "../use_calc_selector_state";

import { NestedSelectableList } from "./nested_selectable_list";

import styles from "./nested_list.module.css";

const Message = ({ children }) => (<div style={{ padding: "4px 0" }}>{children}</div>);

export function NestedList(props) {
  const {
    lookupTable,
    allSelected,
    allIndeterminate,
    allDisabled,
    onAllToggle,
    onItemToggle,
    standalone = false,
    emptyListMessage = "No items",
    emptyFilteredListMessage = "No items match the filter",
    allItemsLabel = "All items",
    filterPlaceholder = "Search",
  } = props;

  const [search, setSearch] = useState(null);

  const onSearchFilterChange = (event) => {
    setSearch(event.target.value.toLowerCase().trim() || null);
  };

  const filteredLookupTable = useMemo(() => {
    return filterLookupTable({ lookupTable, search });
  }, [lookupTable, search]);

  const isListPresent = lookupTable.size !== 0;
  const isSearchResultsEmpty = filteredLookupTable.size === 0;

  const listClassName = classNames(styles.itemsSelector__list, {
    [styles.itemsSelector__list__standalone]: standalone,
  });

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Input
        data-testid="filter"
        className={styles.itemsSelector__search}
        placeholder={filterPlaceholder}
        onChange={onSearchFilterChange}
        type="text"
        suffix={<SearchOutlined />}
      />
      <div className={listClassName}>
        {isListPresent && (
          <Row className={styles.itemsSelector__all}>
            <Col>
              <Checkbox
                data-testid="select_all_items_checkbox"
                onChange={onAllToggle}
                checked={allSelected}
                indeterminate={allIndeterminate}
                disabled={allDisabled}
              >
                {allItemsLabel}
              </Checkbox>
            </Col>
          </Row>
        )}
        {!isListPresent && (<Message>{emptyListMessage}</Message>)}
        {isListPresent && isSearchResultsEmpty && (<Message>{emptyFilteredListMessage}</Message>)}

        <NestedSelectableList
          lookupTable={filteredLookupTable}
          onItemToggle={onItemToggle}
        />
      </div>
    </div>
  );
}
