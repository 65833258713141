import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Flex } from "antd";

import { Legend } from "components/_v2/forms/legend";
import { useWizard, WizardSidebar } from "components/_v2/wizard";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { FormTextArea } from "components/forms/inputs/hook_form/_v2/textarea";
import { AppForm, FormSchemaRenderer } from "components/hook_form";

import { validationSchema } from "./validation_schema";

export const StepFormInner = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const { goPrev } = useWizard();

  const fields = [{
    render: () => <Legend marginTop={false} label={t("common:headings:notes")} />,
  }, {
    name: "notes",
    component: FormTextArea,
    label: t("common:terms:notes"),
  }];

  return (
    <FormSchemaRenderer
      dataTestid="notes-form"
      schema={{
        layout: {
          type: "scrollBox",
          sidebar: (
            <WizardSidebar />
          ),
          footer: (
            <Flex style={{ width: "100%" }} gap={8}>
              <Button block size="large" onClick={goPrev}>
                {t("common:actions:prev")}
              </Button>
              <SubmitButton layout={false} loading={isSubmitting}>
                {t("common:actions:save")}
              </SubmitButton>
            </Flex>
          ),
        },
        fields,
      }}
    />
  );
};

export const Step6 = ({ isSubmitting }) => {
  const { finish } = useWizard();
  const { setValue, getValues, formState } = useFormContext();

  return (
    <AppForm
      initialValue={getValues()}
      errors={formState.errors}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        finish();
      }}
    >
      <StepFormInner isSubmitting={isSubmitting} />
    </AppForm>
  );
};
