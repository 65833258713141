import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Table } from "antd";

import EmptySearchResultPlaceholder from "components/empty_search_result_placeholder";
import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import withRouter from "routing/with_router";

import styles from "../crud_table.module.css";

class InnerTable extends Component {
  getOrderParams = () => {
    const { order } = this.props;

    const [orderBy, orderDir] = Object.entries(order)[0];

    return {
      orderBy,
      orderDir,
    };
  };

  columns() {
    const { columns: getColumns } = this.props;

    const columns = getColumns().map((col) => ({ ...col, sortOrder: null }));

    const { orderBy, orderDir } = this.getOrderParams();
    const orderDirFull = orderDir === "asc" ? "ascend" : "descend";

    const colIndex = columns.findIndex(({ key }) => key === orderBy);

    if (colIndex >= 0) {
      columns[colIndex].sortOrder = orderDirFull;
    }

    return columns;
  }

  rowKey() {
    return "id";
  }

  renderNoDataLoading() {
    const { query: { data, isLoading, isFetching } } = this.props;

    const isInitialLoading = isLoading;
    const isSearchLoading = data?.data?.length === 0 && isFetching;

    if (isInitialLoading || isSearchLoading) {
      return <Loading />;
    }

    return null;
  }

  renderNoData() {
    const {
      t,
      msgCreateLink,
      showCreateMessage = true,
      isDataFilteredBySearch = false,
      emptyMessage,
      query: { data, isFetching },
    } = this.props;

    if (isFetching) {
      return null;
    }

    if (data?.data?.length !== 0) {
      return null;
    }

    const actionParams = {
      type: "link",
      createMessageText: t("general:create_first_before"),
      createMessageActionText: t("general:create_first_link"),
      to: msgCreateLink,
    };

    const noDataAction = showCreateMessage ? actionParams : {};

    if (isDataFilteredBySearch) {
      return <EmptySearchResultPlaceholder />;
    }

    return <NoDataPlaceholder emptyMessage={emptyMessage} {...noDataAction} />;
  }

  renderTable() {
    const {
      expandedMethod,
      onExpand,
      onTableChange,
      limit,
      page,
      total,
      query: { data, isFetching, isLoading },
      showHeader = true,
    } = this.props;

    const pagination = {
      "data-testid": "crud_table_pagination",
      current: page,
      pageSize: limit,
      total,
      size: "small",
      showSizeChanger: false,
    };

    if (isLoading) {
      return null;
    }

    const isEmpty = !data || data?.data?.length === 0;

    if (isEmpty) {
      return null;
    }

    return (
      <Table
        style={{ height: "100%" }}
        showHeader={showHeader}
        dataSource={data.data}
        columns={this.columns()}
        rowKey={this.rowKey()}
        pagination={pagination}
        onChange={onTableChange}
        onExpand={onExpand}
        loading={isFetching}
        expandedRowRender={expandedMethod ? expandedMethod() : null}
      />
    );
  }

  render() {
    const { bar, style } = this.props;

    return (
      <div style={{ ...style, display: "flex", flexDirection: "column" }} data-testid="crud_table">
        {bar || null}
        <div className={styles.content__content} style={{ height: "100%" }}>
          {this.renderNoDataLoading()}
          {this.renderNoData()}
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(InnerTable));
