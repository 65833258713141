import config from "../config";

import Collections from "./collections";
import { createStorage } from "./storage";
import transport from "./transport";
import { initWS } from "./ws";

const { HTTPTransport } = transport;

let instance = null;

class Store {
  constructor(opts = {}) {
    if (!instance) {
      this.storage = createStorage({});
      this.settings = { ...opts };

      // Register transport methods
      this.http = new HTTPTransport(this.settings, this.storage);
      this.ws = initWS(this.settings);
      this.transport = this.http;
      const Raw = new Collections.Raw(this);

      this.ARI = new Collections.ARI(this);
      this.AdminApplications = new Collections.AdminApplications(this);
      this.AdminBillingAccounts = new Collections.AdminBillingAccounts(this);
      this.AdminChannelAdapters = new Collections.AdminChannelAdapters(this);
      this.AdminProperties = new Collections.AdminProperties(this);
      this.AdminUsers = new Collections.AdminUsers(this);
      this.AdminWlPartners = new Collections.AdminWlPartners(this);
      this.AdminWlDomains = new Collections.AdminWlDomains(this);
      this.AdminWlEmailSettings = new Collections.AdminWlEmailSettings(this);
      this.AirbnbRatePlans = new Collections.AirbnbRatePlans(this);
      this.App = new Collections.App(this);
      this.Applications = new Collections.Applications(this);
      this.Attachments = new Collections.Attachments(this);
      this.Auth = new Collections.Auth(this);
      this.AvailabilityRules = new Collections.AvailabilityRules(this);
      this.BillingAccounts = new Collections.BillingAccounts(this);
      this.Bookings = new Collections.Bookings(this);
      this.CancellationPolicies = new Collections.CancellationPolicies(this);
      this.ChannelEvents = new Collections.ChannelEvents(this);
      this.ChannelMessagesThreads = new Collections.ChannelMessagesThreads(this);
      this.Channels = new Collections.Channels(this);
      this.CustomAvailabilityOffsets = new Collections.CustomAvailabilityOffsets(this);
      this.CustomClosedToArrivals = new Collections.CustomClosedToArrivals(this);
      this.CustomClosedToDepartures = new Collections.CustomClosedToDepartures(this);
      this.CustomDerivedOptions = new Collections.CustomDerivedOptions(this);
      this.CustomMaxAvailabilities = new Collections.CustomMaxAvailabilities(this);
      this.CustomMaxSells = new Collections.CustomMaxSells(this);
      this.CustomMaxStays = new Collections.CustomMaxStays(this);
      this.CustomMinStayArrivals = new Collections.CustomMinStayArrivals(this);
      this.CustomMinStayThroughs = new Collections.CustomMinStayThroughs(this);
      this.CustomRates = new Collections.CustomRates(this);
      this.CustomStopSells = new Collections.CustomStopSells(this);
      this.EmailTemplates = new Collections.EmailTemplates(this);
      this.ExtraCategories = new Collections.ExtraCategories(this);
      this.Extras = new Collections.Extras(this);
      this.Geocoding = new Collections.Geocoding(this);
      this.GroupUsers = new Collections.GroupUsers(this);
      this.Groups = new Collections.Groups(this);
      this.Integrations = {
        Tripla: new Collections.TriplaIntegration(this),
        Apaleo: new Collections.ApaleoIntegration(this),
      };
      this.HotelPolicies = new Collections.HotelPolicies(this);
      this.LiveFeedEvents = new Collections.LiveFeedEvents(this);
      this.Onboarding = new Collections.Onboarding(this);
      this.PCICards = new Collections.PCICards(this);
      this.Profiles = new Collections.Profiles(this);
      this.Properties = new Collections.Properties(this);
      this.PropertyFacilities = new Collections.PropertyFacilities(this);
      this.PropertyUsers = new Collections.PropertyUsers(this);
      this.RateCategories = new Collections.RateCategories(this);
      this.RatePlans = new Collections.RatePlans(this);
      this.Reviews = new Collections.Reviews(this);
      this.RoomFacilities = new Collections.RoomFacilities(this);
      this.RoomSpaces = new Collections.RoomSpaces(this);
      this.RoomTypes = new Collections.RoomTypes(this);
      this.Scores = new Collections.Scores(this);
      this.StateChanges = new Collections.StateChanges(this);
      this.Tasks = new Collections.Tasks(this);
      this.TaxSets = new Collections.TaxSets(this);
      this.Taxes = new Collections.Taxes(this);
      this.UserApiKeys = new Collections.UserApiKeys(this);
      this.Webhooks = new Collections.Webhooks(this);
      this.WhiteLabelDomains = new Collections.WhiteLabelDomains(this);
      this.WhiteLabelEmailSettings = new Collections.WhiteLabelEmailSettings(this);
      this.WhiteLabelPartners = new Collections.WhiteLabelPartners(this);

      this.subscribe = this.ws.subscribe;
      this.publish = this.ws.publish;
      this.query = Raw.query;

      instance = this;
    } else {
      return instance;
    }
  }
}

const connectionSettings = {
  secure: config.API_SECURE,
  server: config.API_HOST,
};

export default new Store(connectionSettings);
