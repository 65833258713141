import React from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Tag } from "antd";

import { Legend } from "components/_v2/forms/legend";
import { AddButton } from "components/_v2/forms/legend/buttons/add_button";

import useDrawer from "hooks/use_drawer";

import { EditDrawer } from "./edit_drawer";

const TaxItem = ({ tax, isError, onRemove, onEdit }) => {
  const { t } = useTranslation();

  return (
    <Flex justify="space-between">
      <span>
        {isError && <Tag icon={<ExclamationCircleOutlined />} color="red">{t("general:validation_error")}</Tag>}
        {tax.name}
      </span>
      <span>
        <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
        <Button type="link" danger onClick={onRemove} icon={<DeleteOutlined />} />
      </span>
    </Flex>
  );
};

const buildNewTax = () => ({
  type: "flat_tax",
  name: null,
  amount: null,
  chargeMode: null,
  applicabilityRulePresent: false,
});

export const Taxes = ({ name, errors, setValue, control }) => {
  const { t } = useTranslation();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name,
  });

  const { isOpen, payload, open, close } = useDrawer();

  return (
    <div>
      <Legend
        label={t("common:headings:taxes")}
        actions={[
          <AddButton key="add" onClick={() => open({ tax: buildNewTax() })} />,
        ]}
      />

      {fields.map((field, index) => (
        <TaxItem
          key={field.id}
          tax={field}
          isError={Object.keys(errors?.[index] || {}).length !== 0}
          onRemove={() => remove(index)}
          onEdit={() => {
            open({
              tax: field,
              index,
            });
          }}
        />
      ))}

      <EditDrawer
        visible={isOpen}
        tax={payload?.tax}
        errors={errors?.[payload?.index]}
        setValue={setValue}
        onClose={close}
        onSubmit={(data) => {
          if (payload.index === undefined) {
            append(data);
            return;
          }

          update(payload.index, data);
        }}
      />
    </div>
  );
};
