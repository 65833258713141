import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { uniqBy } from "lodash";

import currencyOptions from "config/constants/currency_options";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";
import { Legend } from "components/_v2/forms/legend";
import { CountrySelector } from "components/forms/inputs/hook_form/_v2/country_selector";
import { convertValueToCurrencyFormat, FormCurrency } from "components/forms/inputs/hook_form/_v2/currency";
import { HourSelector } from "components/forms/inputs/hook_form/_v2/hour_selector";
import { FormInput } from "components/forms/inputs/hook_form/_v2/input";
import { FormPropertySelector } from "components/forms/inputs/hook_form/_v2/property_selector";
import { FormRangePicker } from "components/forms/inputs/hook_form/_v2/range_picker";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { FormTextArea } from "components/forms/inputs/hook_form/_v2/textarea";
import { GlobalErrors } from "components/forms/inputs/hook_form/global_errors/global_errors";
import { StyledForm } from "components/forms/styled_form";

import { daysBetween } from "utils/dates";
import dayjs from "utils/dayjs";

import { Deposits } from "../common/deposits";
import { Rooms } from "../common/rooms";
import { Services } from "../common/services";

export const EditBookingFormBody = ({ bookingId, roomTypes, ratePlans, otaNames, isSubmitting }) => {
  const { t } = useTranslation();

  const [status, bookingDateRange, currency] = useWatch({ name: ["status", "bookingDateRange", "currency"] });
  const today = dayjs().startOf("day");

  const { setValue, getValues, formState } = useFormContext();

  // recalculate room days when booking date range changes
  useEffect(() => {
    if (!bookingDateRange?.[0] || !bookingDateRange?.[1]) {
      return;
    }

    const rooms = getValues("rooms");

    const newDays = daysBetween(bookingDateRange[0], bookingDateRange[1]).slice(0, -1);

    (rooms || []).forEach((room, index) => {
      const roomDays = newDays.reduce((acc, day) => {
        acc[day] = room.days[day] || null;
        return acc;
      }, {});

      setValue(`rooms.${index}.days`, roomDays);
    });
  }, [bookingDateRange, getValues, setValue]);

  const otaNameOptions = uniqBy(otaNames, (i) => i.code.toLowerCase())
    .map((otaName) => ({
      label: otaName.title,
      value: otaName.code,
    }));

  const paymentCollectOptions = [{
    label: t("common:terms:ota"),
    value: "ota",
  }, {
    label: t("common:terms:property"),
    value: "property",
  }];

  const paymentTypeOptions = [{
    label: t("common:terms:credit_card"),
    value: "credit_card",
  }, {
    label: t("common:terms:bank_transfer"),
    value: "bank_transfer",
  }];

  const statusOptions = [{
    label: t("common:dicts:booking_status:modified"),
    value: "modified",
  }, {
    label: t("common:dicts:booking_status:cancelled"),
    value: "cancelled",
  }];

  return (
    <BodyContainer>
      <ScrollableContainer padding="16px 16px">
        <GlobalErrors />

        <StyledForm>
          <FormPropertySelector
            name="propertyId"
            disabled
            label={t("common:terms:property")}
          />
          {bookingId && (
            <FormSelect
              name="status"
              disabled={status === "cancelled" && !formState.dirtyFields.status}
              label={t("common:terms:status")}
              options={statusOptions}
            />
          )}
          <FormInput
            name="otaReservationCode"
            label={t("common:terms:ota_reservation_code")}
          />
          <FormSelect
            name="otaName"
            label={t("common:terms:ota_name")}
            options={otaNameOptions}
          />
          <FormRangePicker
            name="bookingDateRange"
            label={t("general:dates")}
            disabledDate={(date, { from }) => {
              if (date.isBefore(today)) {
                return true;
              }

              if (from && date.isSame(from, "day")) {
                return true;
              }

              if (from && date.isAfter(from.add(6, "months").startOf("day"))) {
                return true;
              }

              return false;
            }}
          />
          <HourSelector
            name="arrivalHour"
            label={t("common:terms:arrival_hour")}
          />

          <Legend label={t("common:headings:payment")} />
          <FormSelect
            name="paymentCollect"
            label={t("common:terms:payment_collect")}
            options={paymentCollectOptions}
          />
          <FormSelect
            name="paymentType"
            label={t("common:terms:payment_type")}
            options={paymentTypeOptions}
          />
          <FormSelect
            name="currency"
            label={t("common:terms:currency")}
            options={currencyOptions({ label: "label" })}
            onFieldChange={(newCurrency) => {
              const value = getValues("otaCommission");
              const compatibleValue = convertValueToCurrencyFormat(value, newCurrency);

              setValue("otaCommission", compatibleValue);
            }}
          />
          <FormCurrency
            name="otaCommission"
            label={t("common:terms:ota_commission")}
            currency={currency}
          />

          <Legend label={t("common:headings:customer")} />
          <FormInput
            name="customer.name"
            label={t("common:terms:name")}
          />
          <FormInput
            name="customer.surname"
            label={t("common:terms:surname")}
          />
          <FormInput
            name="customer.address"
            label={t("common:terms:address")}
          />
          <FormInput
            name="customer.zip"
            label={t("common:terms:zip")}
          />
          <FormInput
            name="customer.city"
            label={t("common:terms:city")}
          />
          <CountrySelector
            name="customer.country"
            label={t("common:terms:country")}
            placeholder={t("common:forms:fields:country:placeholder")}
          />
          <FormInput
            name="customer.phone"
            label={t("common:terms:phone")}
          />
          <FormInput
            name="customer.mail"
            label={t("common:terms:email")}
          />

          <Rooms name="rooms" roomTypes={roomTypes} ratePlans={ratePlans} />
          <Services name="services" currency={currency} />
          <Deposits name="deposits" currency={currency} />

          <Legend label={t("common:headings:notes")} />
          <FormTextArea
            name="notes"
            label={t("common:terms:notes")}
          />
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton layout={false} loading={isSubmitting}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
