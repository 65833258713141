import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { DisconnectOutlined, DownOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Input, Modal, Space, Table } from "antd";
import store from "store";

import { Select } from "components/forms/inputs/hook_form/_v2/select";
import NoDataPlaceholder from "components/no_data_placeholder";

import { useDebouncedFunction } from "hooks/use_debounced_function";

import alphabetSort from "utils/alphabet_sort";
import dayjs from "utils/dayjs";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import ManageApiKeyPropertiesModal from "../manage_api_key_properties_modal";
import UserApiKeysCreateModal from "../user_api_keys_create_modal";

import generalStyles from "../../../styles/general.module.css";
import styles from "./user_api_keys.module.css";

const { UserApiKeys } = store;
const ACTION_COLUMNS_WIDTH = 200;
const TABLE_ROW_KEY = "id";

const DATE_FORMAT = "formats:date_time_with_seconds";

const useUserApiKeys = (outTableState) => {
  const filters = {};

  if (outTableState.filters?.isWithdrawn !== "all") {
    filters.is_withdrawn = outTableState.filters?.isWithdrawn === "withdrawn";
  }

  if (outTableState.filters?.search) {
    filters.title = {
      has: outTableState.filters.search,
    };
  }

  return useQuery({
    queryKey: ["userApiKeys", outTableState],
    queryFn: () => UserApiKeys.list(filters, outTableState.pagination, outTableState.order),
    onError: showErrorMessageFromResponse,
  });
};

export default function UserApiKeysTable() {
  const { t } = useTranslation();
  const [isCreateModalOpen, setCreateModalOpen, setCreateModalClose] = useBoolState(false);
  const [
    isManagePropertiesModalOpen,
    setManagePropertiesModalOpen,
    setManagePropertiesModalClose,
  ] = useBoolState(false);
  const [managedAPIKeyID, setManagedAPIKeyID] = useState(null);
  const [useApiKeyFilters, setApiKeyTableState] = useState({
    filters: {
      isWithdrawn: "all",
    },
  });

  const handleSearchChange = useCallback((e) => {
    setApiKeyTableState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        search: e.target.value,
      },
      pagination: {
        ...prev.pagination,
        page: 1,
      },
    }));
  }, [setApiKeyTableState]);
  const debouncedHandleSearchChange = useDebouncedFunction(handleSearchChange, 500);

  const handleWithdrawFilterChange = useCallback((value) => {
    setApiKeyTableState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        isWithdrawn: value,
      },
      pagination: {
        ...prev.pagination,
        page: 1,
      },
    }));
  }, [setApiKeyTableState]);

  const handlePaginationChange = useCallback((newPagination) => {
    setApiKeyTableState((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: newPagination.current,
        limit: newPagination.pageSize,
      },
    }));
  }, [setApiKeyTableState]);

  const queryClient = useQueryClient();
  const userApiKeysQuery = useUserApiKeys(useApiKeyFilters);
  const userApiKeys = userApiKeysQuery.data?.entities || [];

  const withdraw = useCallback(async (keyId) => {
    await UserApiKeys.withdraw(keyId);
    await queryClient.invalidateQueries({ queryKey: ["userApiKeys"] });
  }, [queryClient]);

  const handleWithdraw = useCallback(
    (keyId) => {
      Modal.confirm({
        title: t("user_api_keys:withdraw_confirmation:title"),
        content: (
          <div data-testid="modal_content">{t("user_api_keys:withdraw_confirmation:description")}</div>
        ),
        onOk: () => withdraw(keyId),
      });
    },
    [t, withdraw],
  );

  const handleManageProperties = useCallback(
    (keyId) => {
      setManagedAPIKeyID(keyId);
      setManagePropertiesModalOpen();
    },
    [setManagedAPIKeyID, setManagePropertiesModalOpen],
  );

  const handleManagePropertiesClose = useCallback(() => {
    setManagePropertiesModalClose();
    setTimeout(() => {
      setManagedAPIKeyID(null);
    }, 300);
  }, [setManagedAPIKeyID, setManagePropertiesModalClose]);

  const handleCreateApiKey = useCallback(async (val) => {
    const key = await UserApiKeys.create(val);
    void queryClient.invalidateQueries({ queryKey: ["userApiKeys"] });

    return key;
  }, [queryClient]);

  const handleSaveManagedProperties = useCallback(
    (val) => {
      return UserApiKeys.update(val.userApiKey.id, val).then((_result) => {
        handleManagePropertiesClose();
      });
    },
    [handleManagePropertiesClose],
  );

  const renderStatus = useCallback((val) => {
    const color = val ? "red" : "green";
    return <Badge color={color} />;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        className: styles.titleColumn,
        title: t("user_api_keys:table_header:title"),
        key: "title",
        dataIndex: "title",
        sorter: alphabetSort("title"),
      },
      {
        title: t("user_api_keys:table_header:api_key"),
        key: "key",
        dataIndex: "key",
      },
      {
        className: styles.noWrap,
        title: t("user_api_keys:table_header:properties"),
        key: "isRestricted",
        dataIndex: "isRestricted",
        align: "center",
        render: (isRestricted) => (
          isRestricted ? t("user_api_keys:table_content:only_selected") : t("user_api_keys:table_content:all")
        ),
      },
      {
        className: styles.noWrap,
        title: t("user_api_keys:table_header:is_withdrawn"),
        key: "isWithdrawn",
        dataIndex: "isWithdrawn",
        align: "center",
        render: (value) => renderStatus(value),
      },
      {
        className: styles.noWrap,
        title: t("user_api_keys:table_header:inserted_at"),
        key: "insertedAt",
        dataIndex: "insertedAt",
        render: (value) => dayjs(value).format(t(DATE_FORMAT)),
      },
      {
        title: t("general:actions"),
        key: "actions",
        align: "right",
        width: ACTION_COLUMNS_WIDTH,
        render: (_value, record) => {
          const items = [];

          if (record.isRestricted) {
            items.push({
              key: "user_api_keys_table_actions_manage_properties",
              disabled: record.isWithdrawn,
              onClick: () => handleManageProperties(record.id),
              label: (
                <div>
                  <DisconnectOutlined /> {t("user_api_keys:table_actions:manage_properties")}
                </div>
              ),
            });
          }

          items.push({
            key: "user_api_keys_table_actions_withdraw",
            disabled: record.isWithdrawn,
            onClick: () => handleWithdraw(record.id),
            label: (
              <div>
                <DisconnectOutlined /> {t("user_api_keys:table_actions:withdraw")}
              </div>
            ),
          });

          return (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a
                data-testid="crud_entry_actions_menu"
                className={generalStyles.actionsToggle}
                onClick={(e) => e.preventDefault()}
              >
                {t("general:actions")} <DownOutlined />
              </a>
            </Dropdown>
          );
        },
      },
    ];
  }, [t, handleManageProperties, handleWithdraw, renderStatus]);

  const isVisibleTable = userApiKeys?.length || userApiKeysQuery.isLoading;

  const pagination = {
    current: userApiKeysQuery.data?.meta?.page || 1,
    pageSize: userApiKeysQuery.data?.meta?.limit || 10,
    total: userApiKeysQuery.data?.meta?.total || 0,
  };

  return (
    <main>
      <div className={styles.header}>
        <Space>
          <p className={styles.title}>{t("user_api_keys:header_title")}</p>
          <Input
            placeholder={t("general:search_field_placeholder")}
            suffix={<SearchOutlined className={styles.contentSearchIcon} />}
            allowClear
            onChange={debouncedHandleSearchChange}
          />
        </Space>
        <Space>
          <Select
            style={{ width: 120 }}
            defaultValue="all"
            placeholder={t("user_api_keys:filter_placeholder")}
            onChange={handleWithdrawFilterChange}
            options={[
              { value: "all", label: t("common:terms:all") },
              { value: "active", label: t("common:terms:active") },
              { value: "withdrawn", label: t("api_keys:terms:withdrawn") },
            ]}
          />
          <Button icon={<PlusCircleOutlined />} type="link" onClick={setCreateModalOpen}>
            {t("user_api_keys:create_btn")}
          </Button>
        </Space>
      </div>
      <div className={styles.tableContainer}>
        {isVisibleTable ? (
          <Table
            loading={userApiKeysQuery.isFetching}
            pagination={pagination}
            onChange={handlePaginationChange}
            dataSource={userApiKeys}
            columns={columns}
            rowKey={TABLE_ROW_KEY}
          />
        ) : (
          <NoDataPlaceholder
            type="button"
            onClick={setCreateModalOpen}
            emptyMessage={t("user_api_keys:empty_message")}
            createMessageText={t("general:create_first_before")}
            createMessageActionText={t("general:create_first_link")}
          />
        )}
      </div>
      <UserApiKeysCreateModal
        visible={isCreateModalOpen}
        onCreate={handleCreateApiKey}
        onClose={setCreateModalClose}
      />

      {managedAPIKeyID !== null ? (
        <ManageApiKeyPropertiesModal
          apiKeyID={managedAPIKeyID}
          visible={isManagePropertiesModalOpen}
          onSave={handleSaveManagedProperties}
          onClose={handleManagePropertiesClose}
        />
      ) : null}
    </main>
  );
}
