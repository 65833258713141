import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import store from "store";
import { convertToCamelCase } from "store/utils/case_converter";

import { Actions } from "components/drawers/actions/actions";
import { Content } from "components/drawers/content/content";
import RefreshButton from "components/forms/buttons/refresh_button";
import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import { FormCheckbox } from "components/forms/inputs/hook_form/form_checkbox";
import FormInput from "components/forms/inputs/hook_form/form_input";
import FormSelect from "components/forms/inputs/hook_form/form_select";
import { FormTextarea } from "components/forms/inputs/hook_form/form_textarea";
import InputGroup from "components/forms/inputs/input_group/input_group";
import { useAppForm } from "components/hook_form";

import { validationSchema } from "./validation_schema";

import styles from "./styles.module.css";

const { Channels } = store;

const useUpdateBookingSettings = (channelId) => {
  return useMutation((data) => Channels.airbnbUpdateBookingSettings(channelId, data));
};

const useReloadListingBookingSettings = (channelId) => {
  return useMutation((listingId) => Channels.airbnbLoadListingBookingSettings(channelId, listingId));
};

const prepareDefaultValue = (bookingSettings) => {
  if (bookingSettings) {
    const convertedValue = convertToCamelCase(bookingSettings);

    if (convertedValue.checkOutTime !== undefined && convertedValue.checkOutTime !== null) {
      convertedValue.checkOutTime = String(convertedValue.checkOutTime);
    }

    convertedValue.guestControls.allowsChildrenAsHost = Boolean(convertedValue.guestControls.allowsChildrenAsHost);
    convertedValue.guestControls.allowsInfantsAsHost = Boolean(convertedValue.guestControls.allowsInfantsAsHost);
    convertedValue.guestControls.allowsPetsAsHost = Boolean(convertedValue.guestControls.allowsPetsAsHost);
    convertedValue.guestControls.allowsSmokingAsHost = Boolean(convertedValue.guestControls.allowsSmokingAsHost);
    convertedValue.guestControls.allowsEventsAsHost = Boolean(convertedValue.guestControls.allowsEventsAsHost);

    return convertedValue;
  }

  return {
    checkInTimeStart: "NOT_SELECTED",
    checkInTimeEnd: "NOT_SELECTED",
    listingExpectationsForGuests: [],
    guestControls: {
      allowsChildrenAsHost: false,
      allowsInfantsAsHost: false,
      allowsPetsAsHost: false,
      allowsSmokingAsHost: false,
      allowsEventsAsHost: false,
    },
  };
};

const FormSectionLegend = ({ legend, actions }) => {
  return (
    <div className={styles.header}>
      <p className={styles.title}>{legend}</p>
      {actions && actions}
    </div>
  );
};

export function ListingBookingSettingsForm({ listing, channelId, onAvailabilityUpdate, onClose }) {
  const { t } = useTranslation();
  const [spinner, setSpinner] = React.useState(false);
  const mutation = useUpdateBookingSettings(channelId);
  const reloadMutation = useReloadListingBookingSettings(channelId);

  const { setValue, handleSubmit, errors, control, watch, reset } = useAppForm({
    defaultValue: prepareDefaultValue(listing.booking_setting),
    validationSchema,
    fieldNames: [
      "checkInTimeEnd",
      "checkInTimeStart",
      "checkOutTime",
      "instantBookingAllowedCategory",
      "cancellationPolicySettings.cancellationPolicyCategory",
      "cancellationPolicySettings.ltsCancellationPolicyId",
      "cancellationPolicySettings.nonRefundablePriceFactor",
      "guestControls.allowsChildrenAsHost",
      "guestControls.allowsInfantsAsHost",
      "guestControls.allowsSmokingAsHost",
      "guestControls.allowsPetsAsHost",
      "guestControls.allowsEventsAsHost",
      "guestControls.petCapacity",
      "guestControls.childrenNotAllowedDetails",
      "instantBookWelcomeMessage",
      "listingExpectationsForGuests",
      "listingExpectationsForGuests.*.type",
      "listingExpectationsForGuests.*.addedDetails",
    ],
    submitHandler: async (formValue) => {
      const apiValue = { ...formValue };

      if (formValue.checkOutTime !== undefined && formValue.checkOutTime !== null) {
        apiValue.checkOutTime = Number(formValue.checkOutTime);
      }

      const result = await mutation.mutateAsync({
        channelRatePlanId: listing.id,
        data: apiValue,
      });

      onAvailabilityUpdate(result);
      onClose();
    },
  });

  const listingExpectationsForGuestsValue = watch("listingExpectationsForGuests");
  const instantBookingAllowedCategoryValue = watch("instantBookingAllowedCategory");
  const guestControlsAllowsChildrenAsHostValue = watch("guestControls.allowsChildrenAsHost");
  const guestControlsAllowsInfantsAsHostValue = watch("guestControls.allowsInfantsAsHost");
  const guestControlsAllowsPetsAsHostValue = watch("guestControls.allowsPetsAsHost");

  const handleRefreshClick = useCallback(async () => {
    setSpinner(true);

    const bookingSettings = await reloadMutation.mutateAsync(listing.listing_id);
    reset(prepareDefaultValue(bookingSettings));

    setSpinner(false);
  }, [reset, reloadMutation, listing.listing_id]);

  const checkInTimeStartOptions = {
    8: "8:00",
    9: "9:00",
    10: "10:00",
    11: "11:00",
    12: "12:00",
    13: "13:00",
    14: "14:00",
    15: "15:00",
    16: "16:00",
    17: "17:00",
    18: "18:00",
    19: "19:00",
    20: "20:00",
    21: "21:00",
    22: "22:00",
    23: "23:00",
    24: "24:00",
    25: "25:00",
    26: "26:00",
    FLEXIBLE: t("airbnb:listing:check_in_start_time:flexible"),
    NOT_SELECTED: t("airbnb:listing:check_in_start_time:not_selected"),
  };

  const checkInTimeEndOptions = {
    8: "8:00",
    9: "9:00",
    10: "10:00",
    11: "11:00",
    12: "12:00",
    13: "13:00",
    14: "14:00",
    15: "15:00",
    16: "16:00",
    17: "17:00",
    18: "18:00",
    19: "19:00",
    20: "20:00",
    21: "21:00",
    22: "22:00",
    23: "23:00",
    24: "24:00",
    25: "25:00",
    26: "26:00",
    27: "27:00",
    FLEXIBLE: t("airbnb:listing:check_in_end_time:flexible"),
    NOT_SELECTED: t("airbnb:listing:check_in_end_time:not_selected"),
  };

  const checkOutTimeOptions = {
    0: "0:00",
    1: "1:00",
    2: "2:00",
    3: "3:00",
    4: "4:00",
    5: "5:00",
    6: "6:00",
    7: "7:00",
    8: "8:00",
    9: "9:00",
    10: "10:00",
    11: "11:00",
    12: "12:00",
    13: "13:00",
    14: "14:00",
    15: "15:00",
    16: "16:00",
    17: "17:00",
    18: "18:00",
    19: "19:00",
    20: "20:00",
    21: "21:00",
    22: "22:00",
    23: "23:00",
  };

  const instantBookingAllowedCategoryOptions = {
    off: t("airbnb:listing:instant_booking_allowed_category:off"),
    everyone: t("airbnb:listing:instant_booking_allowed_category:everyone"),
    well_reviewed_guests: t("airbnb:listing:instant_booking_allowed_category:well_reviewed_guests"),
    guests_with_verified_identity: t("airbnb:listing:instant_booking_allowed_category:guests_with_verified_identity"),
    well_reviewed_guests_with_verified_identity: t("airbnb:listing:instant_booking_allowed_category:well_reviewed_guests_with_verified_identity"),
  };

  const cancellationPolicyCategoryOptions = {
    flexible: t("airbnb:listing:cancellation_policy_category:flexible"),
    moderate: t("airbnb:listing:cancellation_policy_category:moderate"),
    strict: t("airbnb:listing:cancellation_policy_category:strict"),
    better_strict_with_grace_period: t("airbnb:listing:cancellation_policy_category:better_strict_with_grace_period"),
    strict_14_with_grace_period: t("airbnb:listing:cancellation_policy_category:strict_14_with_grace_period"),
    super_strict_30: t("airbnb:listing:cancellation_policy_category:super_strict_30"),
    super_strict_60: t("airbnb:listing:cancellation_policy_category:super_strict_60"),
    tiered_pricing_non_refundable: t("airbnb:listing:cancellation_policy_category:tiered_pricing_non_refundable"),
    firm_14: t("airbnb:listing:cancellation_policy_category:firm_14"),
  };

  const guestExpectationOptions = {
    requires_stairs: t("airbnb:listing:guest_expectation:requires_stairs"),
    potential_noise: t("airbnb:listing:guest_expectation:potential_noise"),
    has_pets: t("airbnb:listing:guest_expectation:has_pets"),
    limited_parking: t("airbnb:listing:guest_expectation:limited_parking"),
    shared_spaces: t("airbnb:listing:guest_expectation:shared_spaces"),
    limited_amenities: t("airbnb:listing:guest_expectation:limited_amenities"),
    surveillance: t("airbnb:listing:guest_expectation:surveillance"),
    noise_monitor: t("airbnb:listing:guest_expectation:noise_monitor"),
    weapons: t("airbnb:listing:guest_expectation:weapons"),
    animals: t("airbnb:listing:guest_expectation:animals"),
    pool_or_jacuzzi_with_no_fence: t("airbnb:listing:guest_expectation:pool_or_jacuzzi_with_no_fence"),
    lake_or_river_or_water_body: t("airbnb:listing:guest_expectation:lake_or_river_or_water_body"),
    climbing_or_play_structure: t("airbnb:listing:guest_expectation:climbing_or_play_structure"),
    heights_with_no_fence: t("airbnb:listing:guest_expectation:heights_with_no_fence"),
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ padding: "0 20px 20px 20px" }}>
        <RefreshButton onClick={handleRefreshClick} />
      </div>

      <Spin spinning={spinner} wrapperClassName={styles.spinContainer}>
        <Content paddingTop={false} paddingBottom={false}>
          <GlobalErrors hookForm errors={errors} />

          <FormSectionLegend legend={t("airbnb:legend:general")} />
          <FormSelect
            name="checkInTimeStart"
            label={t("airbnb:listing:check_in_time_start_label")}
            placeholder={t("airbnb:listing:check_in_time_start_placeholder")}
            control={control}
            errors={errors?.checkInTimeStart?.message}
            options={Object.entries(checkInTimeStartOptions)
              .map(([optionKey, optionValue]) => ({
                value: optionKey,
                label: optionValue,
              }))}
          />
          <FormSelect
            name="checkInTimeEnd"
            label={t("airbnb:listing:check_in_time_end_label")}
            placeholder={t("airbnb:listing:check_in_time_end_placeholder")}
            control={control}
            errors={errors?.checkInTimeEnd?.message}
            options={Object.entries(checkInTimeEndOptions)
              .map(([optionKey, optionValue]) => ({
                value: optionKey,
                label: optionValue,
              }))}
          />
          <FormSelect
            name="checkOutTime"
            label={t("airbnb:listing:check_out_time_label")}
            placeholder={t("airbnb:listing:check_out_time_placeholder")}
            control={control}
            errors={errors?.checkOutTime?.message}
            options={Object.entries(checkOutTimeOptions)
              .map(([optionKey, optionValue]) => ({
                value: optionKey,
                label: optionValue,
              }))}
          />
          <FormSectionLegend legend={t("airbnb:legend:instant_booking")} />
          <FormSelect
            name="instantBookingAllowedCategory"
            label={t("airbnb:listing:instant_booking_allowed_category_label")}
            placeholder={t("airbnb:listing:instant_booking_allowed_category_placeholder")}
            control={control}
            errors={errors?.instantBookingAllowedCategory?.message}
            options={Object.entries(instantBookingAllowedCategoryOptions)
              .map(([optionKey, optionValue]) => ({
                value: optionKey,
                label: optionValue,
              }))}
          />
          {(instantBookingAllowedCategoryValue && instantBookingAllowedCategoryValue !== "off") && (
            <FormTextarea
              name="instantBookWelcomeMessage"
              label={t("airbnb:listing:instant_book_welcome_message_label")}
              placeholder={t("airbnb:listing:instant_book_welcome_message_placeholder")}
              errors={errors?.instantBookWelcomeMessage?.message}
              control={control}
            />
          )}

          <FormSectionLegend legend={t("airbnb:legend:cancellation_policy")} />
          <FormSelect
            name="cancellationPolicySettings.cancellationPolicyCategory"
            label={t("airbnb:listing:cancellation_policy_category_label")}
            placeholder={t("airbnb:listing:cancellation_policy_category_placeholder")}
            control={control}
            errors={errors?.cancellationPolicySettings?.cancellationPolicyCategory?.message}
            options={Object.entries(cancellationPolicyCategoryOptions)
              .map(([optionKey, optionValue]) => ({
                value: optionKey,
                label: optionValue,
              }))}
          />
          <FormSelect
            name="cancellationPolicySettings.ltsCancellationPolicyId"
            label={t("airbnb:listing:lts_cancellation_policy_id_label")}
            placeholder={t("airbnb:listing:lts_cancellation_policy_id_placeholder")}
            control={control}
            errors={errors?.cancellationPolicySettings?.ltsCancellationPolicyId?.message}
            options={[{
              value: "CANCEL_LONG_TERM_FAIR",
              label: t("airbnb:listing:lts_cancellation_policy_id:cancel_long_term_fair"),
            }, {
              value: "CANCEL_LONG_TERM_WITH_GRACE_PERIOD",
              label: t("airbnb:listing:lts_cancellation_policy_id:cancel_long_term_with_grace_period"),
            }]}
          />
          <FormInput
            name="cancellationPolicySettings.nonRefundablePriceFactor"
            label={t("airbnb:listing:non_refundable_price_factor_label")}
            placeholder={t("airbnb:listing:non_refundable_price_factor_placeholder")}
            errors={errors?.cancellationPolicySettings?.nonRefundablePriceFactor?.message}
            control={control}
          />

          <FormSectionLegend legend={t("airbnb:legend:guest_controls")} />
          <FormCheckbox
            name="guestControls.allowsChildrenAsHost"
            label={t("airbnb:listing:allows_children_as_host_label")}
            errors={errors?.guestControls?.allowsChildrenAsHost?.message}
            control={control}
          />
          <FormCheckbox
            name="guestControls.allowsInfantsAsHost"
            label={t("airbnb:listing:allows_infants_as_host_label")}
            errors={errors?.guestControls?.allowsInfantsAsHost?.message}
            control={control}
          />
          {(!guestControlsAllowsChildrenAsHostValue || !guestControlsAllowsInfantsAsHostValue) && (
            <FormInput
              name="guestControls.childrenNotAllowedDetails"
              label={t("airbnb:listing:children_not_allowed_details_label")}
              placeholder={t("airbnb:listing:children_not_allowed_details_placeholder")}
              errors={errors?.guestControls?.childrenNotAllowedDetails?.message}
              control={control}
            />
          )}
          <FormCheckbox
            name="guestControls.allowsSmokingAsHost"
            label={t("airbnb:listing:allows_smoking_as_host_label")}
            errors={errors?.guestControls?.allowsSmokingAsHost?.message}
            control={control}
          />
          <FormCheckbox
            name="guestControls.allowsPetsAsHost"
            label={t("airbnb:listing:allows_pets_as_host_label")}
            errors={errors?.guestControls?.allowsPetsAsHost?.message}
            control={control}
          />
          {guestControlsAllowsPetsAsHostValue && (
            <FormInput
              name="guestControls.petCapacity"
              label={t("airbnb:listing:pet_capacity_label")}
              placeholder={t("airbnb:listing:pet_capacity_placeholder")}
              errors={errors?.guestControls?.petCapacity?.message}
              control={control}
            />
          )}
          <FormCheckbox
            name="guestControls.allowsEventsAsHost"
            label={t("airbnb:listing:allows_events_as_host_label")}
            errors={errors?.guestControls?.allowsEventsAsHost?.message}
            control={control}
          />

          <FormSectionLegend
            legend={t("airbnb:legend:listing_expectations_for_guests")}
            actions={[
              <Button
                data-testid="add-expectations-for-guests"
                key="add-expectations-for-guests"
                icon={<PlusCircleOutlined />}
                type="link"
                onClick={() => {
                  setValue("listingExpectationsForGuests", [
                    ...listingExpectationsForGuestsValue,
                    {
                      type: null,
                      addedDetails: null,
                    },
                  ]);
                }}
              >
                {t("general:action:create")}
              </Button>,
            ]}
          />

          {listingExpectationsForGuestsValue.length === 0 && (
            <p>{t("airbnb:listing:no_expectations_for_guests_message")}</p>
          )}
          {listingExpectationsForGuestsValue.map((expectation, index) => (
            <div className={styles.guestExpectation} key={`${expectation.type}-${expectation.addedDetails}-${index}`}>
              <InputGroup label={t("airbnb:listing:guest_expectation_label")}>
                <FormSelect
                  grouped
                  style={{ width: "100%" }}
                  placeholder={t("airbnb:listing:guest_expectation_type_placeholder")}
                  inputClassName={styles.withActions__oneIcon}
                  name={`listingExpectationsForGuests[${index}].type`}
                  errors={errors?.listingExpectationsForGuests?.[index]?.type?.message}
                  control={control}
                  options={Object.entries(guestExpectationOptions)
                    .map(([optionKey, optionValue]) => ({
                      value: optionKey,
                      label: optionValue,
                    }))}
                />
                <Button
                  type="link"
                  className={styles.action}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const newValue = listingExpectationsForGuestsValue;
                    newValue.splice(index, 1);
                    setValue("listingExpectationsForGuests", newValue);
                  }}
                />
              </InputGroup>
              <InputGroup
                label={<span />}
                className={styles.emptyLabel}
              >
                <FormTextarea
                  grouped
                  groupMain
                  maxLength={300}
                  placeholder={t("airbnb:listing:guest_expectation_added_details_placeholder")}
                  inputClassName={styles.underActions__oneIcon}
                  name={`listingExpectationsForGuests[${index}].addedDetails`}
                  errors={errors?.listingExpectationsForGuests?.[index]?.addedDetails?.message}
                  control={control}
                />
              </InputGroup>
            </div>
          ))}
        </Content>

        <Actions>
          <SubmitButton onClick={() => { handleSubmit(); }} loading={mutation.isLoading}>
            {t("general:action:save")}
          </SubmitButton>
        </Actions>
      </Spin>
    </div>
  );
}
