import { initReactI18next } from "react-i18next";
import * as Sentry from "@sentry/react";
import en from "i18n/en.json";
import i18n from "i18next";
import queryString from "query-string";

import SUPPORTED_LANGUAGES from "config/constants/supported_languages";

import AppStorageManager from "utils/app_storage_manager";

import dayjs from "../utils/dayjs";

import { LokexBackend } from "./lokex_backend";

const DEFAULT_LANG = "en";

const isLanguageSupported = (lngCode) => {
  return SUPPORTED_LANGUAGES.find((language) => language.key === lngCode);
};

const query = queryString.parse(window.location.search);
const queryLang = query.lng?.toLowerCase();

AppStorageManager.removeItem("CHANNEX_LANGUAGE");
if (isLanguageSupported(queryLang)) {
  AppStorageManager.setItem("CHANNEX_LANGUAGE", queryLang);
}

const getDefaultLanguage = () => {
  const sessionLang = AppStorageManager.getItem("CHANNEX_LANGUAGE");
  if (isLanguageSupported(sessionLang)) {
    return sessionLang;
  }

  const user = AppStorageManager.getItem("CHANNEX_USER");
  const userLang = user?.preferred_language;
  if (isLanguageSupported(userLang)) {
    return userLang;
  }

  const browserLanguage = window.navigator.language?.substring(0, 2);
  if (isLanguageSupported(browserLanguage)) {
    return browserLanguage;
  }

  return DEFAULT_LANG;
};

export const initI18n = i18n
  .use(initReactI18next)
  .use(LokexBackend)
  .init({
    backend: {
      defaultTranslation: en,
      urls: [
        "https://cdn.lokex.io/translations/50ba2612-ef0a-4b7b-8c79-a1f4a6ce5fdc/{{lng}}.json",
      ],
      onLoadFailure: ((error) => {
        Sentry.captureException(error);
      }),
    },
    interpolation: { escapeValue: false },
    lng: getDefaultLanguage(),
    fallbackLng: DEFAULT_LANG,
    keySeparator: ":",
  });

i18n.on("languageChanged", (lng) => {
  dayjs.locale(lng);
});

export { i18n };
