import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Input, notification, Space } from "antd";
import store from "store";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import ManageSubscriptionDrawer from "./manage_subscription_drawer";

import ErrorImage from "static/too-many-properties.svg";
import BillingAccountsSubPageStyles from "./billing_accounts_sub_page.module.css";
import generalStyles from "styles/general.module.css";

const { AdminBillingAccounts } = store;
const DROPDOWN_TRIGGER = ["click"];

class BillingAccountsSubPage extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    columnsToHide: 0,
    tableQuery: "",
    error: null,
    filter: {},
    manageSubscriptionDrawer: {
      visible: false,
      billingAccountId: null,
    },
  };

  tableRef = React.createRef();

  actions = (_text, record) => {
    const { t, routes, navigate } = this.props;

    const items = [{
      key: "crud_admin_billing_account_edit_action",
      onClick: () => navigate(pathBuilder(routes.userAppRoutes.admin.billing_accounts.edit, { billingAccountId: record.id })),
      label: (
        <div data-testid="crud_admin_billing_account_edit_action">
          {t("general:action:edit")}
        </div>
      ),
    }, {
      key: "billing_accounts_sub_page_actions_sync",
      onClick: this.onSync(record.id),
      label: (
        <div>
          {t("billing_accounts_sub_page:actions:sync")}
        </div>
      ),
    }];

    if (!record.has_subscription) {
      items.push({
        key: "billing_accounts_sub_page_actions_create_subscription",
        onClick: this.onCreateSubscription(record.id),
        label: (
          <div>
            {t("billing_accounts_sub_page:actions:create_subscription")}
          </div>
        ),
      });
    }

    return (
      <Dropdown menu={{ items }} trigger={DROPDOWN_TRIGGER}>
        <a
          data-testid="crud_entry_actions_menu"
          className={generalStyles.actionsToggle}
          onClick={(event) => event.preventDefault()}
        >
          {t("general:actions")} <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  loadData = (query, pagination, order) => {
    const { filter } = this.state;
    const queryFilter = { ...filter, company_name: { has: query } };

    return AdminBillingAccounts.list(queryFilter, pagination, order).then(
      (response) => response,
      (error) => {
        this.setState({ error: error.errors.code });
        return error;
      },
    );
  };

  onSync = (id) => {
    const { t } = this.props;

    return () => {
      AdminBillingAccounts.sync(id).then(
        (_response) => notification.success({
          message: t("billing_accounts_sub_page:notifications:sync:header"),
          description: t("billing_accounts_sub_page:notifications:sync:success"),
        }),
        (error) => {
          if (error.errors.code === "validation_error") {
            notification.error({
              message: t("billing_accounts_sub_page:notifications:sync:header"),
              description: error.errors.details.has_subscription?.join("\r\n"),
            });
          } else {
            notification.error({
              message: t("billing_accounts_sub_page:notifications:sync:header"),
              description: t("common:errors:request_failed"),
            });
          }
        },
      );
    };
  };

  onCreateSubscription = (id) => {
    return () => {
      this.setState({
        manageSubscriptionDrawer: {
          visible: true,
          billingAccountId: id,
        },
      });
    };
  };

  columns = () => {
    const { t } = this.props;

    let dataColumns = [
      {
        title: t("billing_accounts_sub_page:columns:company_name"),
        dataIndex: "company_name",
        key: "company_name",
        sorter: true,
      },
      {
        title: t("billing_accounts_sub_page:columns:owner_name"),
        dataIndex: "owner.name",
        key: "owner.name",
        sorter: false,
        render: (_value, entity) => {
          return entity.owner?.name;
        },
        ...this.getColumnSearchProps("owner.name"),
      },
      {
        title: t("billing_accounts_sub_page:columns:owner_email"),
        dataIndex: "owner.email",
        key: "owner.email",
        sorter: false,
        render: (_value, entity) => {
          return entity.owner?.email;
        },
        ...this.getColumnSearchProps("owner.email"),
      },
      {
        title: t("billing_accounts_sub_page:columns:memberships"),
        dataIndex: "memberships_count",
        key: "memberships_count",
        sorter: true,
        align: "right",
      },
      {
        title: t("billing_accounts_sub_page:columns:properties"),
        dataIndex: "properties_count",
        key: "properties_count",
        sorter: true,
        align: "right",
      },
      {
        title: t("billing_accounts_sub_page:columns:has_subscription"),
        dataIndex: "has_subscription",
        key: "has_subscription",
        sorter: true,
        align: "center",
        render: (value) => {
          return <Badge status={value === true ? "success" : "error"} />;
        },
      },
    ];

    const actionColumns = [
      {
        className: BillingAccountsSubPageStyles.columnActions,
        title: t("billing_accounts_sub_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions,
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage() {
    const { t } = this.props;
    return t("billing_accounts_sub_page:empty_message");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    this.setState({ tableQuery });
  };

  getClosePath = () => {
    const { routes } = this.props;
    const { tableQuery } = this.state;
    const basePath = pathBuilder(routes.userAppRoutes.admin.billing_accounts);
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  getColumnSearchProps = (dataIndex) => {
    const { filter } = this.state;
    const { t } = this.props;
    const value = filter[dataIndex];

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={t("general:crud_table:filter:search")}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("general:crud_table:filter:search")}
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              {t("general:crud_table:filter:reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              {t("general:crud_table:filter:filter")}
            </Button>
          </Space>
        </div>
      ),
      filteredValue: value,
    };
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    const { filter } = this.state;
    filter[dataIndex] = selectedKeys[0];
    this.setState({ filter });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    const { filter } = this.state;
    delete filter[dataIndex];
    this.setState({ filter });
    clearFilters();
  };

  handleCloseManageSubscriptionDrawer = () => {
    this.tableRef.current.reloadTable();

    this.setState({
      manageSubscriptionDrawer: {
        visible: false,
      },
    });
  };

  render() {
    const { data, t } = this.props;
    const closePath = this.getClosePath();
    const { error, manageSubscriptionDrawer } = this.state;

    if (error) {
      return <Page.ErrorMessage text={t(`users_sub_page:errors:${error}`)} icon={ErrorImage} />;
    }

    return (
      <>
        <Media query="(max-width: 419px)" onChange={this.handleMediaChange(4)} />
        <Media
          query="(min-width: 420px) and (max-width: 679px)"
          onChange={this.handleMediaChange(3)}
        />
        <Media
          query="(min-width: 680px) and (max-width: 899px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 900px) and (max-width: 930px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 931px)" onChange={this.handleMediaChange(0)} />

        <CRUDTable.Container>
          <CRUDTable
            data={data}
            componentRef={this.tableRef}
            emptyMessage={this.emptyMessage()}
            showCreateMessage={false}
            onTablePramsChange={this.handleTableQueryChange}
            columns={this.columns}
            loadData={this.loadData}
            showTableWithoutResults
            internalLoading
          />
        </CRUDTable.Container>

        <Outlet context={{ closePath }} />

        <ManageSubscriptionDrawer
          visible={manageSubscriptionDrawer.visible}
          billingAccountId={manageSubscriptionDrawer.billingAccountId}
          onClose={this.handleCloseManageSubscriptionDrawer}
        />
      </>
    );
  }
}

const mapStateToProps = ({ adminBillingAccounts }) => {
  const { entities } = adminBillingAccounts || { entities: null };

  return {
    data: entities ? Object.values(entities) : null,
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(BillingAccountsSubPage)));
