import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Empty, Row, Table, Tag, Typography } from "antd";
import { _ } from "lodash";
import store from "store";

import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";
import SubmitButton from "components/forms/buttons/submit_button";
import Loading from "components/loading";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";
import showErrorMessage from "utils/show_error_message";

import useApplicationAction from "./hooks/use_application_action";

import styles from "./shared.module.css";

const { Text } = Typography;

const { ws } = store;

const { Applications } = store;
const ACTIONS = ["reply_to_message", "respond_to_review", "mark_as_no_show"];
const ACTION_COLUMNS_WIDTH = 100;

export default function MakeSettings({ installation, onClose }) {
  const { t } = useTranslation();
  const [settings, setSettings] = useState(null);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const { propertyId, id } = installation;

  const { userAppRoutes } = useRouting();

  const {
    handleSubmit,
    setError,
  } = useForm();

  const { data: apiKey, refetch: generateAPIKey, isSuccess: isAPIKeyGenerated, isFetching: isAPIKeyGenerating } = useApplicationAction({
    appCode: "make_com",
    action: "generate_api_key",
    query: { propertyId },
  }, {
    enabled: false,
    retry: false,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
    onError: () => {
      showErrorMessage(t("applications_page:make_com:service_unavailable"));
    },
  });

  const isAPIKeyExisted = isAPIKeyGenerated && apiKey && apiKey.key;

  const handleConnectionRequestSuccess = useCallback(
    () => {
      const redirectRoute = pathBuilder(userAppRoutes.applications.settings, {
        installationId: id,
      });

      if (window.location.pathname === redirectRoute) {
        window.location.reload();
      }
    },
    [id, userAppRoutes],
  );

  useEffect(() => {
    if (settings === null) {
      setSettings(installation.settings || { webhooks: [] });
    }
  }, [settings, apiKey, installation]);

  useEffect(() => {
    const propertyChannel = ws.socket.channel(`property:${propertyId}`, {});
    propertyChannel.join();

    propertyChannel.on("application_updated", handleConnectionRequestSuccess);

    return () => {
      propertyChannel.leave();
    };
  }, [propertyId, handleConnectionRequestSuccess]);

  const handleOnRemove = (webhook) => {
    return () => {
      settings.webhooks.splice(settings.webhooks.indexOf(webhook), 1);
      setSettings({ ...settings });
    };
  };

  const columns = [
    {
      title: t("applications_page:make_com:webhook_url"),
      key: "webhook",
      dataIndex: "webhook",
      ellipsis: true,
    },
    {
      title: t("applications_page:make_com:events"),
      dataIndex: "events",
      key: "events",
      render: (events) => {
        return events.map((event) => <Tag key={event}>{t(`applications_page:make_com:${event}`)}</Tag>);
      },
    },
    {
      title: t("applications_page:make_com:actions"),
      dataIndex: "actions",
      key: "actions",
      align: "right",
      width: ACTION_COLUMNS_WIDTH,
      render: (_value, webhook) => {
        const items = [];

        items.push({
          key: "applications_page:make_com:remove_button",
          onClick: handleOnRemove(webhook),
          label: (
            <div>
              {t("applications_page:make_com:remove_button")}
            </div>
          ),
        });

        return (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <a
              data-testid="crud_entry_actions_menu"
              onClick={(e) => e.preventDefault()}
            >
              {t("general:actions")} <DownOutlined />
            </a>
          </Dropdown>
        );
      },
    }];

  const submit = () => {
    setSettings({ ...settings });
    setSaveInProgress(true);

    Applications.update({ ...installation, settings })
      .then(onClose)
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const parsedErrors = parseApiErrors(error);
        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, []);

        setError("global", globalErrors);
        Object.entries(formErrors).forEach(([key, value]) => {
          setError(key, value);
        });
      })
      .finally(() => setSaveInProgress(false));
  };

  if (settings === null) {
    return <Loading />;
  }

  return (
    <div>
      <Row>
        <Col span={11} className={styles.roomTitle}>
          {t("applications_page:make_com:id")}
        </Col>
        <Col span={1} className={styles.arrow}>
          &rarr;
        </Col>
        <Col span={12} className={styles.roomTitle}>
          {id}
          <CopyToClipboard text={id} />
        </Col>

        <Col span={11} className={styles.roomTitle}>
          {t("applications_page:make_com:api_key")}
        </Col>
        <Col span={1} className={styles.arrow}>
          &rarr;
        </Col>

        <Col>
          <div style={{ lineHeight: "40px" }}>
            <Button
              loading={isAPIKeyGenerating}
              onClick={() => {
                generateAPIKey();
              }}
            >
              {t("applications_page:make_com:generate_api_key")}
            </Button>
          </div>
        </Col>
      </Row>

      {isAPIKeyExisted && (
      <Row>
        <Col>
          <div>
            <p className={styles.apiKey__title}>{t("applications_page:make_com:api_key_description")}</p>
            <div className={styles.apiKey__inner}>
              <Text code>{apiKey.key}</Text>
              <CopyToClipboard text={apiKey.key} />
            </div>
          </div>
        </Col>
      </Row>
      )}

      <Row className={styles.columnHeaders}>
        <Col span={24}>
          {t("applications_page:make_com:triggers")}
        </Col>
      </Row>

      <Row>
        <Col span={24}>

          <div className={styles.wrapper}>
            {settings.webhooks?.length ? (
              <Table
                showHeader={false}
                columns={columns}
                dataSource={[...settings.webhooks]}
                rowKey={(record) => record.webhook + record.events}
                pagination={false}
              />
            ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </div>
        </Col>

      </Row>

      <Row className={styles.columnHeaders}>
        <Col span={24}>
          {t("applications_page:make_com:actions")}
        </Col>
      </Row>

      <Row className={styles.roomTitle}>
        <Col span={24}>
          {t("applications_page:make_com:actions_description")}
        </Col>
      </Row>

      {ACTIONS.map((action) => (
        <Row key={action} className={styles.roomTitle}>
          {t(`applications_page:make_com:${action}`)}
        </Row>
      ))}
      <div className={styles.actions}>
        <SubmitButton onClick={() => { handleSubmit(submit)(); }} loading={saveInProgress}>
          {t("applications_page:make_com:save")}
        </SubmitButton>
      </div>
    </div>
  );
}
