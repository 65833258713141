import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import FormWrapper from "components/billing_account/form_wrapper";

function BillingAccountDrawer() {
  const { t } = useTranslation();
  const { billingAccountId } = useParams();
  const title = t(
    billingAccountId
      ? "billing_account_drawer:edit_header"
      : "billing_account_drawer:create_header",
  );

  return (
    <ChannexDrawerRoutable title={title} dataTestid="billing_account_drawer">
      {({ handleCloseFromContent }) => (
        <FormWrapper entityId={billingAccountId} onClose={handleCloseFromContent} />
      )}
    </ChannexDrawerRoutable>
  );
}

export default BillingAccountDrawer;
