import { useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Legend } from "components/_v2/forms/legend";
import { FormCurrency } from "components/forms/inputs/hook_form/_v2/currency";

const DaysContent = ({ name, days, currency }) => {
  const { t } = useTranslation();

  return (
    (days).sort().map((date) => (
      <FormCurrency
        key={date}
        name={`${name}.${date}`}
        label={date}
        placeholder={t("common:forms:fields:amount:placeholder")}
        currency={currency}
      />
    ))
  );
};

export const Days = ({ name, days, currency }) => {
  const { t } = useTranslation();

  const formState = useFormState();
  const daysError = formState.errors[name];

  return (
    <>
      <Legend
        label={t("common:headings:days")}
        error={daysError?.message}
      />
      <DaysContent name={name} days={days} currency={currency} />
    </>
  );
};
