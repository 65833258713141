/* eslint-disable no-console */

import { Socket } from "phoenix-channels";

import { getAuthTokens } from "../../auth/tokens";

const CONNECTION_ERROR_MSG = "there was an error with the connection!";
const CONNECTION_CLOSE_MSG = "the connection dropped";
const API_CHANNEL = "api";
const JWT_FIELD = "__jwt";
const JOIN_SUCCESS_MSG = "Joined successfully";
const JOIN_ERROR_MSG = "Unable to join";
const TIMEOUT_ERROR_MSG = "Timeout Error";
const EVENTS = [
  "property:state_changed",
  "channel:health_changed",
  "channel_rate_plan:settings_changed",
  "airbnb:mapping_removed",
  "airbnb:mapping_updated",
];
const subscriptions = {};

async function createSocket(context) {
  const { authToken } = getAuthTokens();

  const socket = new Socket(`${context.settings.secure ? "wss" : "ws"}://${context.settings.server}/socket`, {
    params: {
      token: authToken,
    },
  });

  socket.onError(async () => {
    if (socket.params.token) {
      try {
        const { authToken: newAuthToken } = getAuthTokens();

        socket.params.token = newAuthToken;
      } catch (e) {
        console.log(CONNECTION_ERROR_MSG);
      }
    }
  });
  socket.onClose(() => console.log(CONNECTION_CLOSE_MSG));

  context.socket = socket;

  return new Promise((resolve) => {
    socket.onOpen(resolve);
    socket.connect();
  });
}

function connectAPIChannel(context) {
  const apiChannel = context.socket.channel(API_CHANNEL, {});

  apiChannel
    .join()
    .receive("ok", (resp) => {
      console.log(JOIN_SUCCESS_MSG, resp);
    })
    .receive("error", (resp) => {
      console.log(JOIN_ERROR_MSG, resp);
    })
    .receive("timeout", () => {
      console.log(TIMEOUT_ERROR_MSG);
    });

  context.apiChannel = apiChannel;
}

function publishEvent(event, payload) {
  if (!subscriptions[event]) {
    return;
  }

  subscriptions[event].forEach((item) => {
    item(payload !== undefined ? payload : {});
  });
}

function receiveEvent(event) {
  return (payload) => {
    publishEvent(event, payload);
  };
}

function subscribeToEvents(context) {
  EVENTS.forEach((event) => {
    context.apiChannel.on(event, receiveEvent(event));
  });
}

export default class WSTransport {
  constructor(settings) {
    this.settings = settings;
  }

  async connect() {
    await createSocket(this);

    connectAPIChannel(this);
    subscribeToEvents(this);
  }

  send(method, endpoint, payload) {
    const { authToken } = getAuthTokens();

    if (authToken) {
      payload[JWT_FIELD] = authToken;
    }

    return new Promise((resolve, reject) => {
      this.apiChannel
        .push(`${method}:${endpoint}`, payload)
        .receive("ok", (resp) => resolve(resp))
        .receive("error", (resp) => reject(resp))
        .receive("timeout", () => reject(Error(TIMEOUT_ERROR_MSG)));
    });
  }

  disconnect() {
    this.socket?.disconnect();
  }

  subscribe(event, callback) {
    if (!subscriptions[event]) {
      subscriptions[event] = [];
    }
    const index = subscriptions[event].push(callback) - 1;

    return {
      remove: () => {
        delete subscriptions[event][index];
      },
    };
  }

  // test helper method
  publish(event, payload) {
    return publishEvent(event, payload);
  }
}
