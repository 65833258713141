import React from "react";
import { FormProvider } from "react-hook-form";

import { FormSchemaRenderer } from "./form_schema_renderer";
import { useAppForm } from "./use_app_form";

export const AppForm = ({ form, schema, children, dataTestid, ...params }) => {
  if (schema && children) {
    throw new Error("You can't use schema and children at the same time");
  }

  const innerForm = children || <FormSchemaRenderer schema={schema} dataTestid={dataTestid} />;

  if (form) {
    return (
      <WithProvidedForm form={form}>
        {innerForm}
      </WithProvidedForm>
    );
  }

  return (
    <WithOwnForm {...params}>
      {innerForm}
    </WithOwnForm>
  );
};

const WithOwnForm = ({ children, ...params }) => {
  const paramsWithDefaults = {
    mode: "onChange",
    reValidateMode: "onChange",
    ...params,
  };

  const form = useAppForm(paramsWithDefaults);

  return (
    <WithProvidedForm form={form}>
      {children}
    </WithProvidedForm>
  );
};

const WithProvidedForm = ({ form, children }) => {
  if (typeof children === "function") {
    return (
      <FormProvider {...form}>
        {children(form)}
      </FormProvider>
    );
  }

  return (
    <FormProvider {...form}>
      {children}
    </FormProvider>
  );
};
