import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Button, Modal } from "antd";
import { bookingsApiClient } from "store/api_clients/bookings";

import channelCodes from "config/constants/channels/channel_codes";

import { showSuccessMessage } from "components/toasts/messages";

import showErrorMessage from "utils/show_error_message";

const CHANNELS = [channelCodes.BookingCom.code];

const useInvalidCardReportMutation = (bookingId) => {
  return useMutation({
    mutationFn: () => {
      return bookingsApiClient.invalidCardReport({ bookingId });
    },
  });
};

const { confirm } = Modal;

export const InvalidCardReport = ({ booking }) => {
  const { t } = useTranslation();

  const invalidCardReportMutation = useInvalidCardReportMutation(booking.id);

  if (!booking.channel) {
    return null;
  }

  if (!CHANNELS.includes(booking.ota_name)) {
    return null;
  }

  const isCreditCardPresent = !!booking.guarantee;

  if (!isCreditCardPresent) {
    return null;
  }

  const handleClick = () => {
    confirm({
      title: t("general:dialog:confirmation:title"),
      content: t("general:dialog:confirmation:content"),
      onOk: () => {
        invalidCardReportMutation.mutate(null, {
          onSuccess: () => showSuccessMessage(),
          onError: () => showErrorMessage(t("general:errors:request_failed")),
        });
      },
    });
  };

  return (
    <Button size="small" danger loading={invalidCardReportMutation.isLoading} onClick={handleClick}>
      {t("bookings:actions:invalid_card")}
    </Button>
  );
};
