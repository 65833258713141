import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { matchRoutes } from "react-router";
import { createRoutesFromChildren, useLocation, useNavigationType } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { App as AntApp, ConfigProvider } from "antd";
import { initI18n } from "i18n/i18n";
import store from "store";

import config from "config";

import { theme } from "./config/theme";
import { isIframe } from "./utils/is_iframe";
import App from "./app";
import { queryClient } from "./query_client";

const { storage } = store;

if (config.GOOGLE_ANALYTICS_MEASUREMENT_ID) {
  ReactGA.initialize(config.GOOGLE_ANALYTICS_MEASUREMENT_ID);

  const opts = {
    iframe: isIframe(),
  };

  const state = storage.getState();
  if (state?.user?.email) {
    opts.email = state.user.email;
  }

  if (state?.session?.appMode) {
    opts.appMode = state.session.appMode;
  }

  ReactGA.set(opts);
}

if (config.SENTRY_DNS) {
  const sentryOptions = {
    dsn: config.SENTRY_DNS,
    environment: config.APP_ENVIRONMENT,
    release: config.APP_VERSION,

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
      Sentry.globalHandlersIntegration({
        onunhandledrejection: false, // We handle unhandled rejections ourselves in use_unhandled_rejection_handler.jsx
        onerror: true,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 0.1,
    tracesSampler: ({ name }) => {
      if (name === "refreshTokens") {
        return 1;
      }

      return 0.1;
    },

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      // ResizeObserver
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",

      // Network errors, tmp commented out to capture i18n load problems
      // "TypeError: Failed to fetch",
      // "TypeError: Load failed",
      // "TypeError: Cancelled",
      // "TypeError: NetworkError when attempting to fetch resource.",

      // Community suggestions
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      "Script error.",
    ],
    ignoreUrls: [
      // Community suggestions
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  };

  const state = storage.getState();
  if (state?.user?.email) {
    sentryOptions.initialScope = {
      user: {
        email: state?.user?.email,
      },
    };
  }

  Sentry.init(sentryOptions);
}

const container = document.getElementById("root");
const root = createRoot(container);

function EnvSpecificReactMode({ children }) {
  // in local development need to omit react strict mode because of
  // https://github.com/google-map-react/google-map-react/issues/1116
  if (import.meta.env.DEV) {
    return children;
  }

  return (
    <React.StrictMode>
      {children}
    </React.StrictMode>
  );
}

initI18n.then(() => {
  root.render(
    <React.Suspense fallback="Loading...">
      <EnvSpecificReactMode>
        <QueryClientProvider client={queryClient}>
          <Provider store={storage}>
            <ConfigProvider theme={theme}>
              <AntApp>
                <App />
              </AntApp>
            </ConfigProvider>
          </Provider>
        </QueryClientProvider>
      </EnvSpecificReactMode>
    </React.Suspense>,
  );
});
