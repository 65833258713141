import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Flex, Input, Radio, Space } from "antd";

import Loading from "components/loading/loading";

import styles from "./styles.module.css";

const getStep = (answers, initialStep) => {
  const step = answers.reduce((acc, answer) => {
    if (!acc?.choices) {
      return acc;
    }

    return acc.choices.find((choice) => choice.value === answer);
  }, initialStep);

  return step;
};

const Section = ({ children }) => {
  return (
    <div style={{ marginBottom: 16 }}>
      {children}
    </div>
  );
};

const ModalTitle = ({ children }) => {
  return (
    <h1 className={styles.modalTitle}>{children}</h1>
  );
};

const ModalSubTitle = ({ children }) => {
  return (
    <h1 className={styles.modalSubTitle}>{children}</h1>
  );
};

const ANSWER_REQUIRES_DETAILS = ["OtherReason", "OtherReasons", "BugConcern"];

export function ListingUnpublishWorkflowInner({ flow, answers, isFlowLoading, isSubmitting, submitError, flowLoadError, onNext, onBack, onCancel, onFinish }) {
  const { t } = useTranslation();
  const [answer, setAnswer] = useState(null);

  if (isFlowLoading) {
    return (
      <Section>
        <div style={{ height: 300 }}>
          <Loading />
        </div>
      </Section>
    );
  }

  if (flowLoadError) {
    return (
      <div>
        <Section>
          <Alert
            type="error"
            message={t(flowLoadError)}
          />
        </Section>
        <Button onClick={onCancel} data-testid="cancel">{t("common:actions:cancel")}</Button>
      </div>
    );
  }

  const header = flow.description;

  const initialStep = flow.questions[0];
  const currentStep = getStep(answers, initialStep);
  const stepTitle = currentStep.description;
  const stepChoices = currentStep.choices || [];

  const nextStep = getStep(answer ? [...answers, answer] : answers, initialStep);
  let isLastStep = (nextStep?.choices || []).length === 0;

  if (ANSWER_REQUIRES_DETAILS.includes(answer)) {
    isLastStep = false;
  }

  const isAnswerRequiredStep = ANSWER_REQUIRES_DETAILS.includes(answers[answers.length - 1]);

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleFinish = async () => {
    const resultAnswers = [...answers, answer];

    const last2Answers = resultAnswers.slice(-2);

    if (ANSWER_REQUIRES_DETAILS.includes(last2Answers[0])) {
      onFinish({
        deactivationReason: last2Answers[0],
        deactivationDetails: last2Answers[1],
      });
    } else {
      onFinish({
        deactivationReason: last2Answers[1],
        deactivationDetails: null,
      });
    }
  };

  return (
    <div>
      <ModalTitle>{header}</ModalTitle>

      {submitError && (
        <Section>
          <Alert
            type="error"
            message={submitError}
          />
        </Section>
      )}

      <ModalSubTitle>{stepTitle}</ModalSubTitle>

      <Section>
        {stepChoices.length !== 0 && (
          <Radio.Group value={answer} onChange={handleChange}>
            <Flex vertical>
              {stepChoices.map((question) => (
                <Radio key={question.value} value={question.value}>{question.description}</Radio>
              ))}
            </Flex>
          </Radio.Group>
        )}
        {isAnswerRequiredStep && (
          <Input.TextArea
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            autoSize={{ minRows: 6, maxRows: 6 }}
          />
        )}
      </Section>

      <Flex justify="space-between">
        <Button onClick={onCancel} data-testid="cancel">{t("common:actions:cancel")}</Button>
        <Space>
          <Button
            data-testid="back"
            disabled={answers.length === 0}
            onClick={() => {
              onBack();
              setAnswer(null);
            }}
          >
            {t("common:actions:back")}
          </Button>
          {isLastStep && (
            <Button
              type="primary"
              onClick={handleFinish}
              loading={isSubmitting}
            >
              {t("listings:actions:unpublish")}
            </Button>
          )}
          {isLastStep || (
            <Button
              type="primary"
              disabled={!answer}
              onClick={() => {
                onNext(answer);
                setAnswer(null);
              }}
            >
              {t("common:actions:next")}
            </Button>
          )}
        </Space>
      </Flex>
    </div>
  );
}
