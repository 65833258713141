export const BodyContainer = ({ children, dataTestid }) => (
  <div
    data-testid={dataTestid}
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
    }}
  >
    {children}
  </div>
);
