import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import compose from "recompose/compose";
import store from "store";

import APP_MODES from "config/constants/app_modes";
import { features } from "config/feature_flags";

import Loading from "components/loading";

import withComponentRef from "containers/with_component_ref";

import withRouter from "routing/with_router";

import AssignPropertyForm from "../../drawers/assign_property_form/assign_property_form";

import GroupForm from "./components/group_form/group_form";
import UsersList from "./components/users_list/users_list";
import withUserAccesses from "./containers/with_users_accesses";

const { Groups, BillingAccounts } = store;

const DEFAULT_GROUP = {};
const DEFAULT_ACTIVE_TAB = "details";

class GroupManagement extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
  };

  state = {
    loading: true,
    activeTab: DEFAULT_ACTIVE_TAB,
    group: DEFAULT_GROUP,
    billingAccount: null,
  };

  componentDidMount() {
    const { id } = this.props;

    if (!id) {
      this.initForm(DEFAULT_GROUP);

      return;
    }

    this.loadGroupData();
    this.loadBillingAccount();
  }

  loadGroupData = () => {
    const { id } = this.props;

    return Groups.find(id).then(this.initForm);
  };

  loadBillingAccount = () => {
    BillingAccounts.info().then(
      (billingAccount) => {
        this.setState({ billingAccount });
      },
      (_error) => {},
    );
  };

  initForm = (group) => {
    this.setState({ group, loading: false });
  };

  handleActiveTabChange = (activeTab) => {
    this.setState({ activeTab });
  };

  usersTabIsVisible = () => {
    const { appMode } = this.props;

    if (appMode === APP_MODES.HEADLESS) {
      return this.userManagementIsEnabled();
    }

    return (this.userManagementNotDisabled() && this.hasActiveSubscription()) || this.isAdmin();
  };

  isStaging = () => {
    return window.location.hostname === "staging.channex.io";
  };

  canEditGroups = () => {
    return this.hasActiveSubscription() || this.isAdmin() || this.isStaging();
  };

  userManagementNotDisabled = () => {
    const { activeFeatureFlags } = this.props;

    return activeFeatureFlags[features.DISABLE_USER_MANAGEMENT] !== true;
  };

  userManagementIsEnabled = () => {
    const { activeFeatureFlags } = this.props;
    return activeFeatureFlags[features.ENABLE_USER_MANAGEMENT];
  };

  isAdmin = () => {
    const { user } = this.props;

    return user.system_role === "admin";
  };

  hasActiveSubscription = () => {
    return this.state.billingAccount?.subscription?.isActive === true;
  };

  render() {
    const { t, id, onClose, userAccesses } = this.props;
    const { loading, group, activeTab } = this.state;

    if (loading) {
      return <Loading />;
    }

    const items = [{
      label: <span data-testid="edit_tab">{t("properties_page:tab_keys:edit")}</span>,
      key: "details",
      children: <GroupForm model={group} onClose={onClose} />,
    }];

    if (this.usersTabIsVisible()) {
      items.push({
        label: <span data-testid="users_tab">{t("properties_page:tab_keys:users")}</span>,
        key: "users",
        children: <UsersList groupId={group.id} userAccesses={userAccesses} />,
      });
    }

    items.push({
      label: <span data-testid="properties_tab">{t("properties_page:tab_keys:properties")}</span>,
      key: "properties",
      children: <AssignPropertyForm id={group.id} onClose={onClose} />,
    });

    return id ? (
      <Tabs items={items} defaultActiveKey={activeTab} onChange={this.handleActiveTabChange} />
    ) : (
      <GroupForm model={group} onClose={onClose} />
    );
  }
}

const enhance = compose(withTranslation(), withUserAccesses, withRouter, withComponentRef);

const mapStateToProps = ({ session, user }) => ({
  appMode: session.appMode,
  activeFeatureFlags: session.activeFeatureFlags,
  user,
});

export default connect(mapStateToProps)(enhance(GroupManagement));
