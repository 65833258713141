import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Flex, Row } from "antd";

import currencyOptions from "config/constants/currency_options";

import { Legend } from "components/_v2/forms/legend";
import { HourSelector } from "components/forms/inputs/hook_form/_v2/hour_selector";
import { FormInputNumber } from "components/forms/inputs/hook_form/_v2/input_number";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { FormTextArea } from "components/forms/inputs/hook_form/_v2/textarea";
import { FormCheckbox } from "components/forms/inputs/hook_form/form_checkbox";
import { FormCountrySelect } from "components/forms/inputs/hook_form/form_country_select";
import { FormSchemaRenderer } from "components/hook_form";
import Map from "components/map/map";

import { Stepper } from "../stepper";

import { Photos } from "./photos";
import { Rooms } from "./rooms";

export const ListingFormInner = ({ isCreate, amenitiesQuery, isSubmitting, onGeoCoding, isGeoCodingLoading, onCoordsChange, onBack }) => {
  const { t } = useTranslation();

  const { data: amenities, isLoading: amenitiesIsLoading } = amenitiesQuery;

  const [photos, latitude, longitude] = useWatch({ name: ["photos", "latitude", "longitude"] });

  const amenitiesOptions = useMemo(() => {
    if (!amenities) {
      return [];
    }

    return amenities.map((amenity) => ({
      value: amenity,
      label: t(`common:dicts:facilities:${amenity}`),
    }));
  }, [amenities, t]);

  const roomTypeOptions = [
    { value: "private_room", label: t("listings:dicts:room_type:private_room") },
    { value: "entire_place", label: t("listings:dicts:room_type:entire_place") },
  ];

  const propertyTypeOptions = [
    { value: "aparthotel", label: t("listings:dicts:property_type:aparthotel") },
    { value: "apartment", label: t("listings:dicts:property_type:apartment") },
    { value: "barn", label: t("listings:dicts:property_type:barn") },
    { value: "bed_and_breakfast", label: t("listings:dicts:property_type:bed_and_breakfast") },
    { value: "boat", label: t("listings:dicts:property_type:boat") },
    { value: "boutique_hotel", label: t("listings:dicts:property_type:boutique_hotel") },
    { value: "bungalow", label: t("listings:dicts:property_type:bungalow") },
    { value: "bus", label: t("listings:dicts:property_type:bus") },
    { value: "cabin", label: t("listings:dicts:property_type:cabin") },
    { value: "camper_rv", label: t("listings:dicts:property_type:camper_rv") },
    { value: "campsite", label: t("listings:dicts:property_type:campsite") },
    { value: "casa_particular", label: t("listings:dicts:property_type:casa_particular") },
    { value: "castle", label: t("listings:dicts:property_type:castle") },
    { value: "cave", label: t("listings:dicts:property_type:cave") },
    { value: "chalet", label: t("listings:dicts:property_type:chalet") },
    { value: "condo", label: t("listings:dicts:property_type:condo") },
    { value: "cottage", label: t("listings:dicts:property_type:cottage") },
    { value: "dome_house", label: t("listings:dicts:property_type:dome_house") },
    { value: "earth_house", label: t("listings:dicts:property_type:earth_house") },
    { value: "farm_stay", label: t("listings:dicts:property_type:farm_stay") },
    { value: "guest_suite", label: t("listings:dicts:property_type:guest_suite") },
    { value: "guesthouse", label: t("listings:dicts:property_type:guesthouse") },
    { value: "holiday_park", label: t("listings:dicts:property_type:holiday_park") },
    { value: "hostel", label: t("listings:dicts:property_type:hostel") },
    { value: "houseboat", label: t("listings:dicts:property_type:houseboat") },
    { value: "hotel", label: t("listings:dicts:property_type:hotel") },
    { value: "house", label: t("listings:dicts:property_type:house") },
    { value: "hut", label: t("listings:dicts:property_type:hut") },
    { value: "igloo", label: t("listings:dicts:property_type:igloo") },
    { value: "island", label: t("listings:dicts:property_type:island") },
    { value: "lighthouse", label: t("listings:dicts:property_type:lighthouse") },
    { value: "loft", label: t("listings:dicts:property_type:loft") },
    { value: "nature_lodge", label: t("listings:dicts:property_type:nature_lodge") },
    { value: "ranch", label: t("listings:dicts:property_type:ranch") },
    { value: "resort", label: t("listings:dicts:property_type:resort") },
    { value: "riad", label: t("listings:dicts:property_type:riad") },
    { value: "serviced_apartment", label: t("listings:dicts:property_type:serviced_apartment") },
    { value: "shepherd_hut", label: t("listings:dicts:property_type:shepherd_hut") },
    { value: "shipping_container", label: t("listings:dicts:property_type:shipping_container") },
    { value: "tent", label: t("listings:dicts:property_type:tent") },
    { value: "tiny_house", label: t("listings:dicts:property_type:tiny_house") },
    { value: "tipi", label: t("listings:dicts:property_type:tipi") },
    { value: "tower", label: t("listings:dicts:property_type:tower") },
    { value: "town_house", label: t("listings:dicts:property_type:town_house") },
    { value: "train", label: t("listings:dicts:property_type:train") },
    { value: "tree_house", label: t("listings:dicts:property_type:tree_house") },
    { value: "vacation_home", label: t("listings:dicts:property_type:vacation_home") },
    { value: "villa", label: t("listings:dicts:property_type:villa") },
    { value: "windmill", label: t("listings:dicts:property_type:windmill") },
    { value: "yurt", label: t("listings:dicts:property_type:yurt") },
  ];

  const fields = [{
    render: () => <Legend marginTop={false} label={t("common:headings:basic_info")} />,
  }, {
    name: "title",
    label: t("common:terms:title"),
    placeholder: t("common:forms:fields:title:placeholder"),
  }, {
    name: "description",
    component: FormTextArea,
    label: t("common:terms:description"),
    placeholder: t("common:forms:fields:description:placeholder"),
  }, {
    name: "registrationNumber",
    label: t("listings:terms:registration_number"),
    placeholder: t("listings:forms:fields:registration_number:placeholder"),
  }, {
    name: "sizeSquareFeet",
    label: t("listings:terms:size_square_feet"),
    placeholder: t("listings:forms:fields:size_square_feet:placeholder"),
  }, {
    name: "roomType",
    component: FormSelect,
    label: t("listings:terms:room_type"),
    placeholder: t("common:forms:fields:room_type:placeholder"),
    options: roomTypeOptions,
  }, {
    name: "propertyType",
    component: FormSelect,
    label: t("listings:terms:property_type"),
    placeholder: t("listings:forms:fields:property_type:placeholder"),
    options: propertyTypeOptions,
  }, {
    name: "currency",
    component: FormSelect,
    disabled: true,
    label: t("common:terms:currency"),
    placeholder: t("common:forms:fields:currency:placeholder"),
    options: currencyOptions({ only: ["USD"] }),
  },

  {
    type: "legend",
    label: t("common:headings:location"),
  }, {
    name: "countryCode",
    component: FormCountrySelect,
    label: t("common:terms:country"),
    placeholder: t("common:forms:fields:country:placeholder"),
    disabled: true,
  }, {
    name: "postalCode",
    label: t("common:terms:postal_code"),
    placeholder: t("common:forms:fields:postal_code:placeholder"),
  }, {
    name: "state",
    label: t("common:terms:state"),
    placeholder: t("common:forms:fields:state:placeholder"),
  }, {
    name: "city",
    label: t("common:terms:city"),
    placeholder: t("common:forms:fields:city:placeholder"),
  }, {
    name: "address",
    label: t("common:terms:address"),
    placeholder: t("common:forms:fields:address:placeholder"),
  }, {
    render: () => (
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }}>
          <Button
            style={{ width: "100%", marginBottom: "8px" }}
            type="primary"
            onClick={onGeoCoding}
            icon={<EnvironmentOutlined />}
            loading={isGeoCodingLoading}
          >
            {t("common:actions:find_location")}
          </Button>
        </Col>
      </Row>
    ),
  }, {
    name: "latitude",
    component: FormInputNumber,
    label: t("common:terms:latitude"),
    placeholder: t("common:forms:fields:latitude:placeholder"),
  }, {
    name: "longitude",
    component: FormInputNumber,
    label: t("common:terms:longitude"),
    placeholder: t("common:forms:fields:longitude:placeholder"),
  }, {
    render: () => (
      <Map
        onChangeCoords={onCoordsChange}
        lat={latitude}
        lng={longitude}
      />
    ),
  },

  {
    type: "legend",
    label: t("listings:headings:occupancy_settings"),
  }, {
    name: "maxAdults",
    component: FormInputNumber,
    label: t("common:terms:max_adults"),
    placeholder: t("common:forms:fields:max_adults:placeholder"),
  }, {
    name: "maxChildren",
    component: FormInputNumber,
    label: t("common:terms:max_children"),
    placeholder: t("common:forms:fields:max_children:placeholder"),
  }, {
    name: "numberOfBeds",
    component: FormInputNumber,
    label: t("common:terms:number_of_beds"),
    placeholder: t("common:forms:fields:number_of_beds:placeholder"),
  }, {
    name: "numberOfBedrooms",
    component: FormInputNumber,
    label: t("common:terms:number_of_bedrooms"),
    placeholder: t("common:forms:fields:number_of_bedrooms:placeholder"),
  }, {
    name: "numberOfBathrooms",
    component: FormInputNumber,
    label: t("common:terms:number_of_bathrooms"),
    placeholder: t("common:forms:fields:number_of_bathrooms:placeholder"),
  },

  {
    type: "legend",
    label: t("listings:headings:amenities_and_rules"),
  }, {
    name: "amenities",
    component: FormSelect,
    label: t("common:terms:amenities"),
    placeholder: t("common:forms:fields:amenities:placeholder"),
    options: amenitiesOptions,
    mode: "multiple",
    loading: amenitiesIsLoading,
  }, {
    name: "petsAllowed",
    component: FormCheckbox,
    label: t("common:terms:pets_allowed"),
  }, {
    name: "smokingAllowed",
    component: FormCheckbox,
    label: t("common:terms:smoking_allowed"),
  }, {
    name: "partiesAllowed",
    component: FormCheckbox,
    label: t("common:terms:parties_allowed"),
  }, {
    name: "houseRules",
    component: FormTextArea,
    label: t("common:terms:house_rules"),
    placeholder: t("common:forms:fields:house_rules:placeholder"),
  },

  {
    type: "legend",
    label: t("listings:headings:check_in_out"),
  }, {
    name: "checkInInstructions",
    component: FormTextArea,
    label: t("common:terms:check_in_instructions"),
    placeholder: t("common:forms:fields:check_in_instructions:placeholder"),
  }, {
    name: "checkInStartTime",
    component: HourSelector,
    label: t("common:terms:check_in_start_time"),
    placeholder: t("common:forms:fields:check_in_start_time:placeholder"),
  }, {
    name: "checkInEndTime",
    component: HourSelector,
    label: t("common:terms:check_in_end_time"),
    placeholder: t("common:forms:fields:check_in_end_time:placeholder"),
  }, {
    name: "checkOutStartTime",
    component: HourSelector,
    label: t("common:terms:check_out_start_time"),
    placeholder: t("common:forms:fields:check_out_start_time:placeholder"),
  }, {
    name: "checkOutEndTime",
    component: HourSelector,
    label: t("common:terms:check_out_end_time"),
    placeholder: t("common:forms:fields:check_out_end_time:placeholder"),
  },

  {
    type: "legend",
    label: t("common:headings:photos"),
  }, {
    render: () => {
      if ((photos || []).length < 5) {
        return (
          <Alert
            message={t("listings:errors:required_photos_count", { count: 5 })}
            type="warning"
          />
        );
      }

      return null;
    },
  }, {
    name: "photos",
    component: Photos,
  }, {
    name: "rooms",
    component: Rooms,
  }];

  return (
    <FormSchemaRenderer
      schema={{
        layout: {
          type: "scrollBox",
          containerPadding: isCreate ? "0 16px" : "16px 16px",
          header: isCreate && <Stepper step={1} />,
          footer: (
            <Flex style={{ width: "100%" }} gap={8}>
              {isCreate && (
                <Button size="large" block onClick={onBack}>
                  {t("common:actions:back")}
                </Button>
              )}
              <SubmitButton layout={false} loading={isSubmitting}>
                {t("common:actions:save")}
              </SubmitButton>
            </Flex>
          ),
        },
        fields,
      }}
    />
  );
};
