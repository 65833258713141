import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Button, Checkbox, Divider, Flex, Form, Input, Popover, Tooltip } from "antd";
import { bookingsApiClient } from "store/api_clients/bookings";

import channelCodes from "config/constants/channels/channel_codes";

import { useTimezones } from "hooks/use_timezones";

import { addHours, isBetween, now } from "utils/dates";

import styles from "./styles.module.css";

const REPORT_HOURS_INTERVAL = 48;
const CHANNELS = [channelCodes.BookingCom.code, channelCodes.Expedia.code];

const useNoShowReportMutation = (bookingId) => {
  return useMutation({
    mutationFn: ({ collectFee, feeAmount }) => {
      return bookingsApiClient.noShowReport({ bookingId, collectFee, feeAmount });
    },
  });
};

const PopoverContent = ({ booking, onClose }) => {
  const { t } = useTranslation();
  const [collectFee, setCollectFee] = useState(false);
  const [feeAmount, setFeeAmount] = useState(null);

  const noShowReportMutation = useNoShowReportMutation(booking.id);

  const handleSendClick = () => {
    noShowReportMutation.mutate({ collectFee, feeAmount }, {
      onSuccess: onClose,
    });
  };

  const showFeeAmount = (booking.ota_name === channelCodes.Expedia.code) && collectFee;

  return (
    <Form
      className={styles.form}
      layout="horizontal"
      size="small"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    >
      <Form.Item
        name="collect_fee"
        label={t("no_show_report:label:collect_fee")}
        style={{ marginBottom: 0 }}
      >
        <Checkbox
          checked={collectFee}
          onChange={(e) => { setCollectFee(e.target.checked); }}
        />
      </Form.Item>
      {showFeeAmount && (
        <Form.Item
          name="collect_fee"
          label={t("no_show_report:label:amount")}
          style={{ marginBottom: 0 }}
        >
          <Input
            value={feeAmount}
            onChange={(e) => { setFeeAmount(e.target.value); }}
          />
        </Form.Item>
      )}
      <Divider style={{ margin: "12px 0" }} />
      <Flex justify="flex-end">
        <Button type="primary" size="small" loading={noShowReportMutation.isLoading} onClick={handleSendClick}>{t("general:action:submit")}</Button>
      </Flex>
    </Form>
  );
};

export const NoShowReport = ({ booking, property }) => {
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState(false);
  const { isLoading, applyTz } = useTimezones();

  if (!booking.channel) {
    return null;
  }

  if (!CHANNELS.includes(booking.ota_name)) {
    return null;
  }

  if (isLoading) {
    return (
      <Button disabled={isLoading}>
        {t("no_show_report:button")}
      </Button>
    );
  }

  let startOfDayForArrivalDate = new Date(`${booking.arrival_date}T00:00:00`);

  if (property.timezone) {
    startOfDayForArrivalDate = applyTz(startOfDayForArrivalDate, property.timezone);
  }

  const reportEndDate = addHours(startOfDayForArrivalDate, REPORT_HOURS_INTERVAL);

  const isEnabled = isBetween(now(), startOfDayForArrivalDate, reportEndDate);

  const handleClose = () => {
    setShowPopover(false);
  };

  let button = (
    <Button disabled={!isEnabled}>
      {t("no_show_report:button")}
    </Button>
  );

  if (!isEnabled) {
    button = (
      <Tooltip title={t("no_show_report:text:available_action_time")}>
        {button}
      </Tooltip>
    );
  }

  return (
    <Popover
      destroyTooltipOnHide
      title={t("no_show_report:title")}
      trigger="click"
      open={showPopover}
      content={
        <PopoverContent
          booking={booking}
          onClose={handleClose}
        />
      }
      onOpenChange={setShowPopover}
    >
      {button}
    </Popover>
  );
};
