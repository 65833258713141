import React from "react";
import { useFieldArray, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Tag, Typography } from "antd";

import { Legend } from "components/_v2/forms/legend";
import { AddButton } from "components/_v2/forms/legend/buttons/add_button";

import useDrawer from "hooks/use_drawer";

import { EditDrawer } from "./edit_drawer";

const DepositItem = ({ deposit, errors, onRemove, onEdit }) => {
  const { t } = useTranslation();

  const isError = Object.keys(errors || {}).length !== 0;

  return (
    <Flex justify="space-between">
      <span>
        {isError && <Tag icon={<ExclamationCircleOutlined />} color="red">{t("general:validation_error")}</Tag>}
        {deposit.type}: {deposit.amount} {deposit.currency}
      </span>
      <span>
        <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
        <Button type="link" danger onClick={onRemove} icon={<DeleteOutlined />} />
      </span>
    </Flex>
  );
};

const buildNew = ({ currency }) => ({
  currency,
});

export const Deposits = ({ name, currency, layout }) => {
  const { t } = useTranslation();

  const { fields, append, remove, update } = useFieldArray({ name });
  const { isOpen, payload, open, close } = useDrawer();

  const { errors: formErrors } = useFormState();
  const errors = formErrors[name];

  const isEmpty = fields.length === 0;

  return (
    <>
      <Legend
        marginTop={layout !== "embedded"}
        label={t("common:headings:deposits")}
        actions={[
          <AddButton key="add" onClick={() => open({ deposit: buildNew({ currency }) })} />,
        ]}
      />

      {isEmpty && <Typography.Text type="secondary">{t("common:messages:no_items_added")}</Typography.Text>}

      {fields.map((field, index) => (
        <DepositItem
          key={field.id}
          deposit={field}
          errors={errors?.[index]}
          onRemove={() => remove(index)}
          onEdit={() => {
            open({
              deposit: field,
              index,
            });
          }}
        />
      ))}

      <EditDrawer
        visible={isOpen}
        deposit={payload?.deposit}
        errors={errors?.[payload?.index]}
        onClose={close}
        onSubmit={(data) => {
          if (payload.index === undefined) {
            append(data);
            return;
          }

          update(payload.index, data);
        }}
      />
    </>
  );
};
