import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

const WizardContext = createContext(null);

export const WizardProvider = ({ children, initialStep = 0, steps, onComplete }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(initialStep);
  const [state, setState] = useState({});
  const [stepsErrors, setStepsErrors] = useState([]);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [seenSteps, setSeenSteps] = useState([0]);

  const completeStep = useCallback((index) => {
    setCompletedSteps((newSteps) => [...newSteps, index]);
  }, [setCompletedSteps]);

  const goNext = useCallback(() => {
    setCurrentStepIndex((i) => i + 1);
    completeStep(currentStepIndex);
    setSeenSteps((newSteps) => [...newSteps, currentStepIndex + 1]);
  }, [setCurrentStepIndex, completeStep, currentStepIndex]);

  const goPrev = useCallback(() => {
    setCurrentStepIndex((i) => i - 1);
  }, [setCurrentStepIndex]);

  const goTo = useCallback((index) => {
    setCurrentStepIndex(index);
  }, [setCurrentStepIndex]);

  const finish = useCallback(() => {
    onComplete(state);
  }, [state, onComplete]);

  const setStepsErrorsFn = useCallback((errors) => {
    setStepsErrors(errors);
  }, [setStepsErrors]);

  const value = useMemo(() => ({
    steps,
    currentStepIndex,
    state,
    setState,
    goNext,
    goPrev,
    goTo,
    finish,
    stepsErrors,
    setStepsErrors: setStepsErrorsFn,
    completedSteps,
    completeStep,
    seenSteps,
    onComplete,
  }), [steps, currentStepIndex, state, setState, goNext, goPrev, goTo, finish, stepsErrors, setStepsErrorsFn, completedSteps, completeStep, seenSteps, onComplete]);

  return (
    <WizardContext.Provider value={value}>
      {children}
    </WizardContext.Provider>
  );
};

export const useWizard = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error("useWizard must be used within WizardProvider");
  }

  return context;
};
